<script setup>
import { ref, onMounted, computed } from "vue";
import BaseLayout from "@/Layouts/BaseLayout.vue";
import LoadingComponent from "@/Components/UI/LoadingComponent.vue";
import BannerCarousel from "@/Components/Home/BannerCarousel.vue";
import FeaturedGames from "@/Components/Home/FeaturedGames.vue";
import ProvidersList from "@/Components/Home/ProvidersList.vue";
import HttpApi from "@/Services/HttpApi.js";
import { sidebarStore } from "@/Stores/SideBarStore.js";
import { useBannerStore } from "@/Stores/BannerStore.js";

const sidebarMenuStore = sidebarStore();
const bannerStore = useBannerStore();

const isLoading = ref(true);

const banners = computed(() => bannerStore.banners);
const bannersHome = computed(() => bannerStore.bannersHome);

const providers = ref(null);
const featured_games = ref(null);

const getAllGames = async () => {
	try {
		const response = await HttpApi.get("games/all");
		providers.value = response.data.providers;
		isLoading.value = false;
	} catch (error) {
		console.error(error);
		isLoading.value = false;
	}
};

const getFeaturedGames = async () => {
	try {
		const response = await HttpApi.get("games/featured");
		featured_games.value = response.data;
		isLoading.value = false;
	} catch (error) {
		console.error(error);
		isLoading.value = false;
	}
};

const initializeMethods = async () => {
	await Promise.all([
		bannerStore.fetchBanners(),
		getAllGames(),
		getFeaturedGames(),
	]);
};

onMounted(async () => {
	await initializeMethods();
});
</script>

<template>
    <BaseLayout>
        <LoadingComponent :isLoading="isLoading">
            <div class="text-center">
                <span>{{ $t('Loading data from the platform') }}</span>
            </div>
        </LoadingComponent>

        <div v-if="!isLoading" class="container mx-auto px-2" :class="{
               'md:px-4': sidebarMenuStore.sidebarStatus,
               'md:px-8': !sidebarMenuStore.sidebarStatus,
         }">
            <BannerCarousel :banners="banners" :bannersHome="bannersHome"/>
            <div class="my-4" v-if="featured_games">
                <FeaturedGames :featuredGames="featured_games"/>
            </div>
            <div class="my-4">
                <ProvidersList :providers="providers"/>
            </div>
        </div>
    </BaseLayout>
</template>
