<script setup>
import BaseLayout from "@/Layouts/BaseLayout.vue";
import { ref, onMounted, computed } from "vue";
import HttpApi from "@/Services/HttpApi.js";
import GameList from "@/Components/Cassino/GameList.vue";
import EmptyDataMessage from "@/Components/Cassino/EmptyDataMessage.vue";
import BannerCarousel from "@/Components/Home/BannerCarousel.vue";
import { useBannerStore } from "@/Stores/BannerStore.js";
import Footer from "@/Components/Cassino/AllGames/Footer.vue";
import ProvidersDropdown from "@/Components/Cassino/AllGames/ProvidersDropdown.vue";
import CategoriesDropdown from "@/Components/Cassino/AllGames/CategoriesDropdown.vue";

const isLoading = ref(true);
const bannerStore = useBannerStore();
const games = ref({ data: [], total: 0 });
const searchTerm = ref("");
const selectedProviders = ref([]);
const selectedCategories = ref([]);
const errorMessage = ref("");
const page = ref(1);
const itemsPerPage = 12;

const banners = computed(() => bannerStore.banners);

const providers = ["Provider1", "Provider2", "Provider3"];

function debounce(func, delay) {
    let timeout;
    return function (...args) {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            func(...args);
        }, delay);
    };
}

const fetchGames = async () => {
    isLoading.value = true;
    errorMessage.value = "";

    try {
        const response = await HttpApi.get(
            `/casinos/games?page=${page.value}&searchTerm=${encodeURIComponent(
                searchTerm.value
            )}&providers=${selectedProviders.value.join(
                ","
            )}&categories=${selectedCategories.value.join(",")}`
        );
        const fetchedGames = response.data.games;

        if (page.value === 1) {
            games.value = fetchedGames;
            return;
        }

        games.value.data = [...games.value.data, ...fetchedGames.data];
        games.value.total = fetchedGames.total;
    } catch (error) {
        console.error(error);
        errorMessage.value = "Ocorreu um erro ao carregar os jogos.";
    } finally {
        isLoading.value = false;
    }
};

const fetchBanners = async () => {
    try {
        await bannerStore.fetchBanners();
    } catch (error) {
        console.error("Erro ao carregar banners", error);
    }
};

const categories = ref([]);

const fetchCategories = async () => {
    try {
        const response = await HttpApi.get("/categories");
        categories.value = response.data.categories;
    } catch (error) {
        console.error("Erro ao carregar categorias", error);
    }
};

const searchGamesDebounced = debounce(async () => {
    console.log("Valor de pesquisa:", searchTerm.value);
    searchTerm.value = searchTerm.value ? searchTerm.value.trim() : "";
    page.value = 1;
    await fetchGames();
}, 500);

const loadMoreGames = async () => {
    if (games.value.data.length < games.value.total) {
        page.value += 1;
        await fetchGames();
    }
};

onMounted(async () => {
    await Promise.all([fetchGames(), fetchBanners(), fetchCategories()]);
    isLoading.value = false;
});
</script>

<template>
    <BaseLayout>
        <div class="container mx-auto text-center">
            <div v-if="isLoading && games.data.length <= 10">
                <p class="mb-6 text-lg pt-24 text-[var(--text-color)]">
                    {{ $t("Loading information...") }}
                </p>
            </div>

            <div v-else class="px-4 sm:px-6 lg:px-8">
                <BannerCarousel :banners="banners" :hideExtras="true" />

                <div class="max-w-6xl xl:px-2 mx-auto py-2 relative">
                    <div
                        class="flex flex-wrap justify-end items-center space-x-4 mb-4"
                    >
                        <input
                            v-model="searchTerm"
                            @input="searchGamesDebounced"
                            class="input-group w-full sm:w-auto !pl-[0.2rem] focus:!border-[var(--ci-primary-color)] focus:ring-[var(--ci-primary-color)] mb-4 sm:mb-0"
                            :placeholder="$t('Search games...')"
                        />

                        <ProvidersDropdown
                            title="Providers"
                            :options="providers"
                            v-model:selectedProviders="selectedProviders"
                            @searchGames="searchGamesDebounced"
                        />

                        <CategoriesDropdown
                            title="Categories"
                            :options="categories"
                            v-model="selectedCategories"
                            @searchGames="searchGamesDebounced"
                        />
                    </div>

                    <div>
                        <GameList
                            v-if="games && games.total > 0"
                            :games="games.data"
                            :key="games.data.length"
                        />
                    </div>

                    <div v-if="errorMessage" class="text-red-500 text-center">
                        {{ errorMessage }}
                    </div>

                    <EmptyDataMessage
                        v-else-if="
                            !errorMessage && (!games || games.total === 0)
                        "
                    />

                    <Footer
                        :games="games"
                        :isLoading="isLoading"
                        @loadMoreGames="loadMoreGames"
                    />
                </div>
            </div>
        </div>
    </BaseLayout>
</template>

<style scoped>
.custom-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    font-size: 1.5rem;
    padding: 0.5rem;
    border-radius: 50%;
}

.custom-arrow.left-0 {
    left: 10px;
}

.custom-arrow.right-0 {
    right: 10px;
}

@media (min-width: 1024px) {
    .custom-arrow {
        font-size: 2rem;
        padding: 1rem;
    }
}

.input-group {
    width: 100%;
    max-width: 400px;
}

.game-image {
    object-fit: cover;
    width: 100%;
    height: auto;
    aspect-ratio: 16 / 9;
}
</style>
