<script setup>
import { ref } from "vue";

const isExpanded = ref(false);
</script>

<template>
    <div class="relative py-6 after:absolute after:left-0 after:top-full after:h-px after:w-full after:bg-white after:opacity-20 after:content-['']">
        <div :class="`text-footer-texts pb-2 text-center text-[12px] text-gray-600 dark:text-gray-400 ${isExpanded ? '' : 'max-h-16 custom-overflow-hidden'}`">
            <p><a href="https://flamix.gg">flamix.gg</a> {{ $t('is an online casino site that provides an immersive and highly entertaining experience for its users. Our platform offers a wide selection of casino games, including cutting-edge slots, roulette, blackjack, poker, and much more. With an intuitive and user-friendly design, we bring the excitement of live games, where you can interact with real dealers in real time, along with a wide variety of jackpots and exclusive promotions.') }}</p>
            <p>{{ $t('Our goal is to deliver the best online casino experience with innovative features such as generous bonuses, detailed statistics, win history, and tips to maximize your chances of winning. Whether you are a casual player or an enthusiast, flamix.gg is the ideal destination for fun and excitement in the world of online casinos.') }}</p>
<!--            <p>{{ $t('To provide the best betting experience for its users, we offer a wide range of features and tools, including detailed game statistics, result history, expert analysis, and much more.') }}</p>-->
<!--            <p>{{ $t('Additionally, the site offers various promotions and exclusive bonuses for its users, increasing profit chances and providing an even more exciting experience.') }}</p>-->
<!--            <p>{{ $t('We are a safe and reliable platform that provides 24/7 customer support. With a team of sports betting and online casino specialists, Donald Bet is committed to ensuring its users have a positive and secure experience while enjoying all the excitement and opportunities offered by sports betting responsibly and consciously.') }}</p>-->
        </div>
        <button @click="isExpanded = !isExpanded" class="pt-12 absolute bottom-[-20px] z-10 w-full bg-gradient-to-t from-[var(--background-base)] to-transparent text-[.7rem] text-gray-600 dark:text-gray-400 md:bottom-[-1px]">
            {{ isExpanded ? $t('See less') : $t('See more') }}
        </button>
    </div>
</template>
