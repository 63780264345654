<script setup>
import { ref } from "vue";

const props = defineProps({
	title: String,
	options: Array,
	modelValue: Array,
});

const emit = defineEmits(["update:modelValue", "searchGames"]);

const isDropdownVisible = ref(false);

const toggleDropdown = () => {
	isDropdownVisible.value = !isDropdownVisible.value;
};

const closeDropdown = () => {
	isDropdownVisible.value = false;
};

const onCategoryChange = (id, isChecked) => {
	const updatedValue = isChecked
		? [...props.modelValue, id]
		: props.modelValue.filter((value) => value !== id);

	emit("update:modelValue", updatedValue);
	emit("searchGames");
};

document.addEventListener("click", (event) => {
	if (!event.target.closest(".dropdown")) {
		closeDropdown();
	}
});
</script>

<template>
    <div class="dropdown relative inline-block text-left">
        <button
            class="dropdown-button px-2 py-1 bg-[var(--ci-primary-color)] rounded-md focus:outline-none"
            @click.stop="toggleDropdown"
        >
            {{ title }}
            <svg
            class="w-3 h-3 ml-1"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            >
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
            </svg>
        </button>

        <div
            v-if="isDropdownVisible"
            class="dropdown-content absolute right-0 mt-2 w-56 bg-[var(--ci-gray-over)] border border-gray-200 rounded-md shadow-lg z-50"
        >
            <div class="p-4">
                <template v-for="category in options" :key="category.id">
                    <div class="flex items-center mb-2">
                        <input
                            :id="`checkbox-${category.id}`"
                            type="checkbox"
                            :value="category.id"
                            :checked="modelValue.includes(category.id)"
                            @change="onCategoryChange(category.id, $event.target.checked)"
                            class="w-4 h-4 bg-gray-100 border-gray-300 rounded focus:ring-[var(--ci-primary-color)]"
                        />
                        <label
                            :for="`checkbox-${category.id}`"
                            class="ml-2 text-sm font-medium text-white"
                        >
                            {{ category.name }}
                        </label>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<style scoped>
.dropdown-button {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.dropdown-content {
    display: block;
}
</style>
