<script setup>
import {ref, computed, onMounted} from "vue";
import {useAuthStore} from "@/Stores/Auth.js";
import {useSettingStore} from "@/Stores/SettingStore.js";
import {useToast} from "vue-toastification";
import HttpApi from "@/Services/HttpApi.js";
import {useModalStore} from "@/Stores/ModalStore.js";
import {useRouter} from 'vue-router';
import {trans} from "laravel-vue-i18n";

const router = useRouter();
const authStore = useAuthStore();
const settingStore = useSettingStore();
const toast = useToast();
const modalStore = useModalStore();

const withdrawal = ref({
    amount: "",
    country: "BR",
    currency: "USD",
    bank_info: null
});

const setting = ref(null);
const wallet = ref(null);
const bankingDetails = ref(null);
const isLoadingWallet = ref(false);
const isLoadingBankingDetails = ref(false);

const formatFieldLabel = (field) => {
    const label = field
        .replace(/([A-Z])/g, ' $1')
        .replace(/([A-Z])([A-Z])(?=[a-z])/g, '$1 $2')
        .toLowerCase()
        .replace(/\b\w/g, letter => letter.toUpperCase());

    return label
        .replace('Cpf', 'CPF')
        .replace('Cnpj', 'CNPJ')
        .replace('Bvn', 'BVN')
        .replace('Iban', 'IBAN')
        .replace('Ifsc', 'IFSC');
};

const formatFieldValue = (field, value) => {
    if (!value) return '';

    switch (field.toLowerCase()) {
        case 'cpf':
            return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
        case 'cnpj':
            return value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
        default:
            return value;
    }
};

const bankFields = computed(() => {
    if (!bankingDetails.value?.details) return [];

    const excludedFields = ['created_at', 'updated_at', 'id', 'user_id'];

    const fields = Object.entries(bankingDetails.value.details)
        .filter(([key]) => !excludedFields.includes(key))
        .map(([key, value]) => ({
            key,
            label: formatFieldLabel(key),
            value: formatFieldValue(key, value)
        }));

    if (bankingDetails.value.titular_name) {
        fields.unshift({
            key: 'titular_name',
            label: 'Account Holder',
            value: bankingDetails.value.titular_name
        });
    }

    return fields;
});

const getWallet = async () => {
    isLoadingWallet.value = true;
    try {
        const response = await HttpApi.get("profile/wallet");
        wallet.value = response.data.wallet;
    } catch (error) {
        Object.entries(JSON.parse(error.request.responseText)).forEach(
            ([key, value]) => {
                toast.error(`${value}`);
            }
        );
    } finally {
        isLoadingWallet.value = false;
    }
};

const getBankingDetails = async () => {
    isLoadingBankingDetails.value = true;
    try {
        const response = await HttpApi.get("wallet/withdraw/banking-details");
        bankingDetails.value = response.data.data;
    } catch (error) {
        toast.error("Erro ao carregar dados bancários");
    } finally {
        isLoadingBankingDetails.value = false;
    }
};

const getSetting = () => {
    const settingData = settingStore.setting;
    if (settingData) {
        setting.value = settingData;
    }
};

const handleCreateAccount = () => {
    router.push('/profile/account-details');
    modalStore.closeWithdrawalModal();
};

const isKycApproved = async () => {
    try {
        const response = await HttpApi.get("profile/kyc-status");
        return response.data.kyc_status;
    } catch (error) {
        console.error(error);
        return null;
    }
};

const submitWithdrawal = async () => {
    const kycStatus = await isKycApproved();

    if (kycStatus !== 'approved') {
        toast.error(trans("You must complete KYC to request withdrawals"));
        return;
    }

    if (!withdrawal.value.amount) {
        toast.error(trans("You need to enter an amount"));
        return;
    }

    if (!bankingDetails.value) {
        toast.error(trans("You need to select or register a bank account"));
        return;
    }

    if (parseFloat(withdrawal.value.amount) < parseFloat(setting.value.min_withdrawal)) {
        toast.error(trans("The minimum withdrawal amount is") + " " + setting.value.min_withdrawal);
        return;
    }

    if (parseFloat(withdrawal.value.amount) > parseFloat(setting.value.max_withdrawal)) {
        toast.error(trans("The maximum withdrawal amount is") + " " + setting.value.max_withdrawal);
        return;
    }

    const requestData = {
        ...withdrawal.value,
        bank_info: bankingDetails.value.details
    };

    HttpApi.post("wallet/withdraw", requestData)
        .then((response) => {
            sessionStorage.setItem(
                "sessionMessage",
                trans("Solicitud de retiro enviada con éxito")
            );
            modalStore.closeWithdrawalModal();
            location.reload();
        })
        .catch((error) => {
            Object.entries(JSON.parse(error.request.responseText)).forEach(
                ([key, value]) => {
                    toast.error(`${value}`);
                }
            );
        });
};

onMounted(async () => {
    if (authStore.isAuth) {
        await Promise.all([getWallet(), getSetting(), getBankingDetails()]);
    }
});
</script>

<template>
    <div>
        <div class="mt-2 p-1 bg-gray-800 rounded-lg flex justify-between items-center">
            <input
                type="number"
                v-model="withdrawal.amount"
                class="w-full bg-transparent border-none rounded-md appearance-none"
                :placeholder="$t('Enter the withdrawal amount')"
                :min="setting?.min_withdrawal"
                :max="setting?.max_withdrawal"
                required
            />
            <span class="text-white">{{ wallet?.currency }}</span>
        </div>
        <div class="mt-1">
            <p class="text-xs ml-1 text-gray-400">
                {{ $t('Minimum withdrawal') }}:
                {{ setting?.min_withdrawal }} {{ wallet?.currency }}
            </p>
        </div>

        <div class="mt-4">
            <div v-if="isLoadingBankingDetails" class="text-center">
                <p>Carregando dados bancários...</p>
            </div>

            <div v-else-if="!bankingDetails" class="space-y-4">
                <p class="text-sm text-gray-400">Nenhuma conta bancária cadastrada</p>
                <button
                    @click="handleCreateAccount"
                    class="w-full p-2 bg-gray-700 hover:bg-gray-600 rounded-lg text-white text-sm"
                >
                    {{ $t('Register Bank Account') }}
                </button>
            </div>

            <div v-else class="space-y-4">
                <div class="bg-gray-800 rounded-lg p-4">
                    <h3 class="text-sm font-medium mb-2">{{ $t('Selected Bank Account') }}:</h3>
                    <div class="space-y-2 text-sm">
                        <p v-for="field in bankFields" :key="field.key" class="flex justify-between">
                            <span class="text-gray-400">{{ $t(field.label) }}:</span>
                            <span class="font-medium">{{ field.value }}</span>
                        </p>
                    </div>
                </div>

                <button
                    @click="handleCreateAccount"
                    class="w-full p-2 bg-gray-700 hover:bg-gray-600 rounded-lg text-white text-sm"
                >
                    {{ $t('Change Bank Account') }}
                </button>
            </div>
        </div>

        <button @click.prevent="submitWithdrawal" class="custom-button mt-6">
            {{ $t('Request Withdrawal') }}
        </button>
    </div>
</template>

<style scoped>
.custom-button {
    width: 100%;
    padding: 0.75rem 1.5rem;
    background-color: var(--ci-primary-color);
    color: #f1f0ef;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.25rem;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0px 0px 8px var(--ci-primary-color);
}

.custom-button:hover {
    background-color: var(--ci-secundary-color);
    box-shadow: 0px 0px 12px var(--ci-secundary-color);
}
</style>
