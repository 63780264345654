import { defineStore } from "pinia";

export const useModalStore = defineStore("ModalStore", {
	state() {
		return {
			isDepositModalOpen: false,
			isWithdrawalModalOpen: false,
		};
	},

	actions: {
		openDepositModal() {
			this.isDepositModalOpen = true;
		},

		closeDepositModal() {
			this.isDepositModalOpen = false;
		},
		openWithdrawalModal() {
			this.isWithdrawalModalOpen = true;
		},
		closeWithdrawalModal() {
			this.isWithdrawalModalOpen = false;
		}
	},
});
