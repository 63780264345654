<script setup>
import {onMounted, ref} from "vue";
import Shortcuts from "@/Components/Home/Shortcuts.vue";
import RecommendGames from "@/Components/Home/RecommendGames.vue";
import SearchGames from "@/Components/Home/SearchGames.vue";
import { register } from 'swiper/element/bundle';

const props = defineProps({
    banners: Array,
    bannersHome: Array,
    hideExtras: {
        type: Boolean,
        default: false,
    },
});

const settings = ref({
    slidesPerView: 1,
    loop: true,
    autoplay: {
        delay: 6000,
    },
    breakpoints: {
        700: {
            slidesPerView: 1,
        },
        1024: {
            slidesPerView: 1,
        },
    },
});

onMounted(() => {
    register();

    const swiperEl = document.querySelector('swiper-container');

    const params = {
        injectStyles: [
            `
                :host {
                    --swiper-theme-color: var(--ci-primary-color);
                    --swiper-navigation-sides-offset: 15px;
                }
            `,
        ],
    };

    Object.assign(swiperEl, params);

    swiperEl.initialize();
});
</script>

<template>
    <div class="carousel-banners">
        <div class="max-w-6xl xl:px-2 mx-auto py-2 relative">
            <div class="mt-10 mb-5 relative z-5 px-2 md:px-0">
                <swiper-container
                    init="false"
                    autoplay="true"
                    navigation="true"
                    pagination="true"
                    loop="true"
                >
                    <swiper-slide v-for="(banner, index) in banners" :key="index">
                        <div class="carousel__item rounded w-full">
                            <a :href="banner.link" class="w-full h-full rounded">
                                <img
                                    :src="`/storage/` + banner.image"
                                    alt=""
                                    class="h-full w-full rounded"
                                />
                            </a>
                        </div>
                    </swiper-slide>
                </swiper-container>
            </div>

            <SearchGames v-if="!hideExtras" />
            <Shortcuts v-if="!hideExtras" />
            <RecommendGames :banners="bannersHome" v-if="!hideExtras" />
        </div>
    </div>
</template>

<style>
.swiper-button-next, .swiper-button-prev {
    color: var(--ci-primary-color) !important;
}
</style>
