<script setup>
import { computed, ref, onMounted } from "vue";
import { useModalStore } from "@/Stores/ModalStore.js";
import HttpApi from "@/Services/HttpApi.js";
import { useToast } from "vue-toastification";
import {trans} from "laravel-vue-i18n";

const modalStore = useModalStore();
const props = defineProps({
  wallet: Object,
  isLoadingWallet: Boolean,
});

const rolloverData = ref({
  rollover: '0.00',
  bonus_rollover: '0.00'
});
const isLoadingRollover = ref(true);

const isWithdrawDisabled = computed(() => {
  const depositRolloverRemaining = parseFloat(rolloverData.value.rollover);
  const balanceTooLow = parseFloat(props.wallet?.total_balance || 0) < 1;

  return balanceTooLow || depositRolloverRemaining > 0;
});

const depositRolloverStatus = computed(() => {
    const remaining = parseFloat(rolloverData.value.rollover);
    const maxValue = remaining * 10;
    const progress = Math.min((maxValue - remaining) / maxValue * 100, 100);

    return {
        remaining,
        progress,
        statusText: remaining > 0
            ? trans('messages.remaining_deposit_rollover', { amount: remaining.toFixed(2) })
            : trans('messages.rollover_completed')
    };
});

const bonusRolloverStatus = computed(() => {
    const remaining = parseFloat(rolloverData.value.bonus_rollover);
    const maxValue = remaining * 10;
    const progress = Math.min((maxValue - remaining) / maxValue * 100, 100);

    return {
        remaining,
        progress,
        statusText: remaining > 0
            ? trans('messages.remaining_bonus_rollover', { amount: remaining.toFixed(2) })
            : trans('messages.rollover_completed')
    };
});
const fetchRolloverData = () => {
  const toast = useToast();
  isLoadingRollover.value = true;

  HttpApi.get('profile/rollover')
    .then((response) => {
      rolloverData.value = response.data;
      isLoadingRollover.value = false;
    })
    .catch((error) => {
      toast.error('Failed to load rollover information');
      isLoadingRollover.value = false;
      console.error(error);
    });
};

onMounted(fetchRolloverData);
const handleDeposit = () => {
  modalStore.openDepositModal();
};

const handleWithdraw = async (event) => {
  event.preventDefault();
  try {
    if (!isWithdrawDisabled.value) {
      modalStore.openWithdrawalModal();
    }
  } catch (error) {
    console.error('Error in handleWithdraw:', error);
  }
};

const handleTransactionHistoryClick = () => {
};
</script>

<template>
  <div v-if="!isLoadingWallet" class="flex flex-col gap-4">
    <div class="w-full p-6 bg-gray-900 rounded-lg">
      <div class="flex items-center justify-between mb-4">
        <div class="flex items-center gap-2">
          <span class="text-xl text-gray-400">USD</span>
          <span class="text-xl text-gray-500">#{{ wallet?.id }}</span>
        </div>
        <i class="text-gray-400 fa-light fa-wallet" style="font-size: 3rem;"></i>
      </div>
      <div class="mb-6">
        <div class="flex items-baseline gap-2">
          <span class="text-5xl font-bold text-white">{{
            state.currencyFormat(parseFloat(wallet?.total_balance || 0), wallet?.currency)
          }}</span>
        </div>
      </div>

      <div v-if="!isLoadingRollover" class="mb-4 space-y-4">
        <div>
          <div class="flex justify-between mb-1 text-xs text-gray-400">
            <span>Deposit Rollover</span>
            <span>{{ depositRolloverStatus.remaining.toFixed(2) }} USD</span>
          </div>
          <div class="w-full h-2 mb-1 bg-gray-700 rounded-full">
            <div
              class="h-2 bg-blue-500 rounded-full"
              :style="{ width: `${depositRolloverStatus.progress}%` }"
            ></div>
          </div>
          <div class="text-xs text-gray-300">
            {{ depositRolloverStatus.statusText }}
          </div>
        </div>

        <div>
          <div class="flex justify-between mb-1 text-xs text-gray-400">
            <span>Bonus Rollover</span>
            <span>{{ bonusRolloverStatus.remaining.toFixed(2) }} USD</span>
          </div>
          <div class="w-full h-2 mb-1 bg-gray-700 rounded-full">
            <div
              class="h-2 bg-green-500 rounded-full"
              :style="{ width: `${bonusRolloverStatus.progress}%` }"
            ></div>
          </div>
          <div class="text-xs text-gray-300">
            {{ bonusRolloverStatus.statusText }}
          </div>
        </div>
      </div>

      <div class="grid grid-cols-2 gap-4 mt-4">
        <button
          @click="handleDeposit"
          type="button"
          class="w-full py-3 px-6 bg-[var(--ci-primary-color)] text-white font-medium rounded-md text-center cursor-pointer transition duration-300 ease-in-out hover:brightness-110"
        >
          {{ $t('Deposit') }}
        </button>
        <button
          @click="handleWithdraw"
          type="button"
          :disabled="isWithdrawDisabled"
          style="background-color: var(--ci-secondary-color, #5555557d);"
          class="w-full px-6 py-3 font-medium text-center text-white transition duration-300 ease-in-out rounded-md cursor-pointer hover:brightness-110 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          {{ $t('Withdraw') }}
        </button>
      </div>
    </div>
    <a
      href="#"
      @click.prevent="handleTransactionHistoryClick"
      class="w-full p-4 mb-4 transition-colors rounded-lg cursor-pointer bg-gray-900/50 hover:bg-gray-900/70"
    >
      <p class="text-sm text-center text-gray-400">
        {{ $t('¿Busca el historial de transacciones? Accede al menú') }}
        <span class="text-blue-400">
          "{{ $t('Gestión de saldo -> Historial de transacciones') }}"
        </span>
      </p>
    </a>
  </div>
  <div
    v-if="isLoadingWallet"
    role="status"
    class="absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2"
  >
  </div>
</template>
