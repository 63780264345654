<script setup>
import { ref, computed, watch } from "vue";
import { useDebounce } from "@vueuse/core";

const props = defineProps({
	columns: {
		type: Array,
		required: true,
	},
	data: {
		type: Object,
		required: true,
		default: () => ({
			data: [],
			current_page: 1,
			from: 0,
			to: 0,
			total: 0,
			last_page: 1,
			per_page: 10,
		}),
	},
	loading: Boolean,
	perPageOptions: {
		type: Array,
		default: () => [10, 25, 50, 100],
	},
});

const emit = defineEmits(["update:filters", "refresh"]);

const searchQuery = ref("");
const debouncedSearch = useDebounce(searchQuery, 300);
const sortColumn = ref("");
const sortDirection = ref("asc");
const perPage = ref(10);
const columnFilters = ref({});

const handleSort = (column) => {
	if (!column.sortable) return;

	if (sortColumn.value === column.key) {
		sortDirection.value = sortDirection.value === "asc" ? "desc" : "asc";
	} else {
		sortColumn.value = column.key;
		sortDirection.value = "asc";
	}
	emitFilters();
};

const handleSearch = (column, value) => {
	columnFilters.value[column.key] = value;
	emitFilters();
};

const handlePerPageChange = (value) => {
	perPage.value = Number(value); // Garante que é um número
	emitFilters();
};

const handlePageChange = (page) => {
	emitFilters({
		page,
		perPage: perPage.value,
	});
};

const emitFilters = (additionalFilters = {}) => {
	emit("update:filters", {
		search: debouncedSearch.value,
		sort: sortColumn.value
			? { column: sortColumn.value, direction: sortDirection.value }
			: null,
		perPage: perPage.value,
		columnFilters: columnFilters.value,
		...additionalFilters,
	});
};

watch(debouncedSearch, () => {
	emitFilters();
});
</script>

<template>
    <div class="w-full">
        <div class="flex flex-col sm:flex-row justify-between items-center mb-4 gap-4">
            <div class="relative w-full sm:w-64">
                <input v-model="searchQuery" type="text"
                    class="w-full px-4 py-2 rounded-lg bg-gray-800 border border-gray-700 text-gray-300 focus:outline-none focus:border-purple-500"
                    :placeholder="$t('Search...')" />
            </div>

            <div class="flex items-center gap-2">
                <span class="text-sm text-gray-400">{{ $t('Per Page') }}:</span>
                <select v-model="perPage" @change="handlePerPageChange($event.target.value)"
                    class="bg-gray-800 border border-gray-700 rounded-lg px-3 py-2 text-gray-300 focus:outline-none focus:border-purple-500">
                    <option v-for="option in perPageOptions" :key="option" :value="option">
                        {{ option }}
                    </option>
                </select>
            </div>
        </div>

        <div class="overflow-x-auto rounded-lg shadow-lg"
            style="background: rgba(30, 35, 45, 0.6); backdrop-filter: blur(10px);">
            <table class="min-w-full divide-y divide-gray-700">
                <thead>
                    <tr>
                        <th v-for="column in columns" :key="column.key" scope="col"
                            class="px-6 py-4 text-left text-xs font-semibold uppercase tracking-wider text-gray-300">
                            <div class="flex items-center gap-2">
                                <span>{{ column.label }}</span>
                                <button v-if="column.sortable" @click="handleSort(column)" class="focus:outline-none">
                                    <i class="fas" :class="{
                                        'fa-sort': sortColumn !== column.key,
                                        'fa-sort-up': sortColumn === column.key && sortDirection === 'asc',
                                        'fa-sort-down': sortColumn === column.key && sortDirection === 'desc'
                                    }">
                                    </i>
                                </button>
                            </div>
                            <input v-if="column.searchable" type="text" :placeholder="`Filter ${column.label}...`"
                                class="mt-2 w-full px-2 py-1 text-xs bg-gray-800 border border-gray-700 rounded focus:outline-none focus:border-purple-500"
                                @input="e => handleSearch(column, e.target.value)" />
                        </th>
                    </tr>
                </thead>

                <tbody class="divide-y divide-gray-700">
                    <template v-if="loading">
                        <tr v-for="i in perPage" :key="i">
                            <td v-for="column in columns" :key="column.key" class="px-6 py-4 whitespace-nowrap">
                                <div class="animate-pulse h-4 bg-gray-700 rounded"></div>
                            </td>
                        </tr>
                    </template>

                    <template v-else-if="data?.data?.length">
                        <tr v-for="row in data.data" :key="row.id"
                            class="hover:bg-gray-800 transition-colors duration-200">
                            <td v-for="column in columns" :key="column.key" class="px-6 py-4 whitespace-nowrap text-sm">
                                <slot :name="column.key" :row="row" :value="row[column.key]">
                                    {{ row[column.key] }}
                                </slot>
                            </td>
                        </tr>
                    </template>

                    <tr v-else>
                        <td :colspan="columns.length" class="px-6 py-8 text-center text-gray-400">
                            {{ $t('No records found') }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="mt-4 flex justify-between items-center" v-if="data?.total > 0">
            <div class="text-sm text-gray-400">
                {{ $t('Showing') }} {{ data?.from || 0 }} - {{ data?.to || 0 }}
                {{ $t('of') }} {{ data?.total || 0 }}
            </div>

            <div class="flex items-center gap-2" v-if="data?.last_page > 1">
                <button v-for="page in data.last_page" :key="page" @click="handlePageChange(page)"
                    class="px-3 py-1 rounded-md text-sm" :class="{
                        'bg-purple-600 text-white': page === data.current_page,
                        'bg-gray-800 text-gray-300 hover:bg-gray-700': page !== data.current_page
                    }">
                    {{ page }}
                </button>
            </div>
        </div>
    </div>
</template>