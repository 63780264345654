<script setup>
import { useFooterStore } from "@/Stores/Footer.js";
import LanguageSelector from "@/Components/Footer/LanguageSelector.vue";
import { ref } from "vue";

const footerText = useFooterStore().footerText;
const logoTM = useFooterStore().logoTM;

defineProps(["year"]);
</script>

<template>
    <div class="text-center grid gap-5 grid-cols-1">
        <div class="flex justify-center items-center">
            <img :src="logoTM.src" :alt="logoTM.alt" :style="{ width: logoTM.width }"/>
        </div>
        <p class="text-[12px]">¡Hecho con ❤️ para todo el mundo!</p>
        <div class="grid gap-5 grid-cols-1">
            <div class="flex items-center justify-end">
                <p class="text-[12px] md:text-sm text-gray-600 dark:text-gray-400 mr-4">
                    {{ footerText }}
                </p>
                <img :src="`/assets/images/icons/bcg.png`" alt="bcg" width="170"/>
            </div>
            <div class="flex flex-col items-center gap-4">
                <div class="flex gap-1">
                    <img :src="`/assets/images/icons/gt_logo.png`" alt="gt" width="70" class="mr-4"/>
                    <img :src="`/assets/images/icons/juega.png`" alt="juega" width="200"/>
                    <img :src="`/assets/images/icons/BeGambleAware.png`" alt="juega" width="200"/>
                </div>
                <div class="mt-2 flex items-center text-gray-600 dark:text-gray-400 text-sm flex-wrap">
                    <span class="font-bold">{{ $t('Support:') }}</span><span class="ml-1 font-bold">support@flamix.gg</span>
                    <span class="mx-2 font-bold">|</span>
                    <span class="font-bold">{{ $t('Legal:') }}</span><span class="ml-1 font-bold">legal@flamix.gg</span>
                    <span class="mx-2 font-bold">|</span>
                    <span class="font-bold">{{ $t('Partners:') }}</span><span class="ml-1 font-bold">partners@flamix.gg</span>
                </div>
                <LanguageSelector />
            </div>
            <div class="flex justify-center items-center">
                <p class="text-[12px]">© {{ year }} Rocket Gaming - {{ $t('All rights reserved.') }}</p>
            </div>
        </div>
    </div>
</template>
