<script setup>
import { computed } from "vue";
import { useModalStore } from "@/Stores/ModalStore.js";

const modalStore = useModalStore();

const props = defineProps({
  wallet: Object,
  isLoadingWallet: Boolean,
});

const isWithdrawDisabled = computed(
  () => parseFloat(props.wallet?.total_balance || 0) < 1,
);

const handleDeposit = () => {
  modalStore.openDepositModal();
};

const handleWithdraw = async (event) => {
  event.preventDefault();
  try {
    if (!isWithdrawDisabled.value) {
      modalStore.openWithdrawalModal();
    }
  } catch (error) {
    console.error('Error in handleWithdraw:', error);
  }
};

const handleTransactionHistoryClick = () => {
};
</script>

<template>
  <div v-if="!isLoadingWallet" class="flex flex-col gap-4">
    <div class="w-full p-6 bg-gray-900 rounded-lg">
      <div class="flex items-center justify-between mb-4">
        <div class="flex items-center gap-2">
          <span class="text-gray-400 text-xl">USD</span>
          <span class="text-gray-500 text-xl">#{{ wallet?.id }}</span>
        </div>
        <i class="fa-light fa-wallet text-gray-400" style="font-size: 3rem;"></i>
      </div>
      <div class="mb-6">
        <div class="flex items-baseline gap-2">
          <span class="text-5xl font-bold text-white">{{
            state.currencyFormat(parseFloat(wallet?.total_balance || 0), wallet?.currency)
          }}</span>
        </div>
      </div>
      <div class="grid grid-cols-2 gap-4">
        <button 
          @click="handleDeposit" 
          type="button"
          class="w-full py-3 px-6 bg-[var(--ci-primary-color)] text-white font-medium rounded-md text-center cursor-pointer transition duration-300 ease-in-out hover:brightness-110"
        >
          {{ $t('Deposit') }}
        </button>
        <button 
          @click="handleWithdraw" 
          type="button"
          :disabled="isWithdrawDisabled"
          style="background-color: var(--ci-secondary-color, #5555557d);" 
          class="w-full py-3 px-6 text-white font-medium rounded-md text-center cursor-pointer transition duration-300 ease-in-out hover:brightness-110 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          {{ $t('Withdraw') }}
        </button>
      </div>
    </div>
    <a 
      href="#" 
      @click.prevent="handleTransactionHistoryClick"
      class="w-full p-4 bg-gray-900/50 hover:bg-gray-900/70 rounded-lg cursor-pointer transition-colors mb-4"
    >
      <p class="text-sm text-gray-400 text-center">
        {{ $t('¿Busca el historial de transacciones? Accede al menú') }}
        <span class="text-blue-400">
          "{{ $t('Gestión de saldo -> Historial de transacciones') }}"
        </span>
      </p>
    </a>
  </div>
  <div 
    v-if="isLoadingWallet" 
    role="status"
    class="absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2"
  >
  </div>
</template>