<script setup>
import { ref, onMounted, computed, reactive } from "vue";
import { useToast } from "vue-toastification";
import HttpApi from "@/Services/HttpApi.js";
import { useDepositCardStore } from "@/Stores/DepositCardStore.js";
import { trans } from "laravel-vue-i18n";

const props = defineProps({
	setting: Object,
	wallet: Object,
	paymentType: String,
    amountOptions: Array,
    loadingDepositCards: Boolean
});

const emit = defineEmits(["depositSuccess"]);
const selectedAmount = ref(0);
const setAmount = (amount) => {
	deposit.amount = amount;
	selectedAmount.value = amount;
};

const isProcessing = ref(false);
const couponError = ref("");

const deposit = reactive({
	amount: "",
	coupon_code: "",
});

const toast = useToast();

const handleSubmit = async () => {
	if (deposit.amount < props.setting.min_deposit) {
		toast.error(`The minimum deposit amount is ${props.setting.min_deposit}.`);
		return;
	}

	if (deposit.amount > props.setting.max_deposit) {
		toast.error(`The maximum deposit amount is ${props.setting.max_deposit}.`);
		return;
	}

	isProcessing.value = true;

	try {
		const response = await HttpApi.post("wallet/deposit/pay", {
			amount: parseFloat(deposit.amount),
			coupon_code: deposit.coupon_code,
			gateway: "stripe-checkout",
		});

		if (response.data.success) {
			deposit.amount = "";
			sessionStorage.setItem("sessionMessage", response.data.message);

            window.location = response.data.data.redirect_url
		} else {
			toast.error(response.data.message);
		}
	} catch (error) {
		if (error.response && error.response.data && error.response.data.message) {
			toast.error(error.response.data.message);
		} else {
            console.log(error);
			toast.error(trans("Error processing the deposit."));
		}
	} finally {
		isProcessing.value = false;
	}
};

async function validateCouponCode() {
	if (!deposit.coupon_code) {
		couponError.value = "";
		return;
	}

	try {
		const response = await HttpApi.post("wallet/deposit/validate-coupon", {
			coupon_code: deposit.coupon_code,
		});

		if (response.data.status === "success") {
			couponError.value = "";
		} else {
			couponError.value = trans(response.data.message);
		}
	} catch (error) {
		if (error.response.status === 422) {
			couponError.value = error.response.data.message;
		}
	}
}
</script>
<template>
    <div class="stripe-deposit-form">
        <form @submit.prevent="handleSubmit">
            <div class="mt-2 p-1 bg-gray-800 rounded-lg flex justify-between items-center">
                <input
                    type="number"
                    v-model="deposit.amount"
                    :min="props.setting.min_deposit"
                    :max="props.setting.max_deposit"
                    step="0.01"
                    required
                    class="w-full border bg-gray-800 border-none rounded-md appearance-none"
                    :placeholder="$t('Enter the value here')"
                />
                <span class="text-white">{{ props.wallet.currency }}</span>
            </div>
            <div class="mt-1">
                <p class="text-xs ml-1 text-gray-400">
                    {{ $t('Minimum deposit') }}:
                    {{ state.currencyFormat(parseFloat(props.setting.min_deposit), props.wallet.currency) }}
                </p>
            </div>

            <div class="mt-5 item-selected px-4">
                <template v-if="!props.loadingDepositCards">
                    <div
                        v-for="(value, index) in props.amountOptions"
                        :key="index"
                        @click.prevent="setAmount(value.amount)"
                        class="item !m-0"
                        style="width: 100%;"
                        :class="{ 'active': selectedAmount === value.amount }"
                    >
                        <button type="button" class="deposit-button min-h-[50px] min-w-[100px]">
                            {{ props.wallet.symbol }} {{ parseFloat(value.amount).toFixed(2) }}
                        </button>
                        <img
                            v-if="selectedAmount === value.amount"
                            class="img-check"
                            :src="`/assets/images/check.webp`"
                            alt=""
                        />
                    </div>
                </template>
                <template v-else>
                    <div class="flex justify-center p-4">
                        <svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8 animate-spin" viewBox="0 0 24 24">
                            <path fill="currentColor"
                                  d="M12 21a9 9 0 1 1 6.18-15.55a.75.75 0 0 1 0 1.06a.74.74 0 0 1-1.06 0A7.51 7.51 0 1 0 19.5 12a.75.75 0 0 1 1.5 0a9 9 0 0 1-9 9"/>
                        </svg>
                    </div>
                </template>
            </div>

            <div class="mb-4">
                <label for="coupon_code" class="text-xs ml-1 text-gray-400">{{ $t('Has a coupon code?') }}</label>
                <div class="bg-gray-800 rounded-lg flex justify-between items-center">
                    <input
                        v-model="deposit.coupon_code"
                        id="coupon_code"
                        @blur="validateCouponCode"
                        class="w-full border bg-gray-800 border-none rounded-md appearance-none outline-none focus:ring-[var(--ci-primary-color)] focus:border focus:border-[var(--ci-primary-color)]"
                        :placeholder="$t('Enter the coupon code here')"
                    />
                </div>
                <div id="couponError" class="text-red-500 mt-2 font-bold" role="alert">{{ couponError }}</div>
            </div>

            <div class="mb-4">
                <button
                    type="submit"
                    :disabled="isProcessing"
                    class="w-full px-4 py-2 bg-[var(--ci-secundary-color)] text-white rounded-md hover:bg-opacity-80 transition-colors"
                >
                    {{ isProcessing ? $t('Processing...') : $t('Deposit via Stripe Checkout') }}
                </button>
            </div>
        </form>
    </div>
</template>


<style scoped>
.item-selected {
    display: flex;
    overflow-x: auto;
    gap: 0.75rem;
}

.custom-button {
    width: 100%;
    padding: 0.75rem 1.5rem;
    background-color: var(--ci-primary-color);
    color: #f1f0ef;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.25rem;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0px 0px 8px var(--ci-primary-color);
}

.custom-button:hover {
    background-color: var(--ci-secundary-color);
    box-shadow: 0px 0px 12px var(--ci-secundary-color);
}
</style>
