<script setup>
import { ref, onMounted } from "vue";
import BaseProfileLayout from "@/Layouts/BaseProfileLayout.vue";
import { useMenuBarStore } from "@/Stores/MenuBarStore.js";
import BaseTable from "@/Layouts/BaseTable.vue";
import DateFilters from "@/Components/Profile/DateFilters.vue";
import { useToast } from "vue-toastification";
import HttpApi from "@/Services/HttpApi.js";

const loading = ref(false);
const transactions = ref({ data: [], meta: {} });
const selectedFilter = ref("today");
const tableFilters = ref({});

const columns = [
	{
		key: "transaction_type",
		label: "Type",
		sortable: true,
		searchable: true,
	},
	{
		key: "amount",
		label: "Amount",
		sortable: true,
		searchable: true,
	},
	{
		key: "type",
		label: "Payment Method",
		sortable: true,
		searchable: true,
	},
	{
		key: "status",
		label: "Status",
		sortable: true,
		searchable: true,
	},
	{
		key: "proof",
		label: "Proof",
		sortable: false,
		searchable: false,
	},
	{
		key: "created_at",
		label: "Date",
		sortable: true,
		searchable: false,
	},
];

onMounted(() => {
	useMenuBarStore().setActiveMenu("wallet");
	fetchTransactions();
});

const fetchTransactions = async () => {
	loading.value = true;
	const _toast = useToast();
	try {
		const response = await HttpApi.get("/wallet/transactions", {
			params: {
				filter: selectedFilter.value,
				...tableFilters.value,
				page: tableFilters.value?.page || 1,
				perPage: tableFilters.value?.perPage || 10,
			},
		});
		transactions.value = response.data.transactions;
	} catch (error) {
		let errorMsg = "Error loading transactions";
		if (error.response?.data) {
			if (error.response.data.errors) {
				const messages = Object.values(error.response.data.errors).flat();
				errorMsg = messages.join(" ");
			} else if (error.response.data.message) {
				errorMsg = error.response.data.message;
			}
		}
		_toast.error(errorMsg);
	} finally {
		loading.value = false;
	}
};

const handleFiltersUpdate = (filters) => {
	tableFilters.value = filters;
	fetchTransactions();
};

const handleDateFilterSelect = (filterKey) => {
	selectedFilter.value = filterKey;
	fetchTransactions();
};

const getTransactionTypeColor = (type) => {
	return type === 'deposit' ? 'text-green-400' : 'text-red-400';
};

const getStatusColor = (status) => {
	switch (status) {
		case 0:
			return 'bg-yellow-900 text-yellow-200'; // Pending
		case 1:
			return 'bg-green-900 text-green-200';  // Completed
		case 2:
			return 'bg-red-900 text-red-200';      // Failed/Cancelled
		case 3:
			return 'bg-red-900 text-red-200';     // Refunded
		default:
			return 'bg-gray-900 text-gray-200';    // Unknown
	}
};

const getPaymentMethodColor = (type) => {
	switch (type) {
		case 'stripe-card':
			return 'bg-purple-900 text-purple-200';
		case 'pix':
			return 'bg-blue-900 text-blue-200';
		case 'stripe_payout':
			return 'bg-orange-900 text-orange-200';
		default:
			return 'bg-gray-900 text-gray-200';
	}
};

const formatStatus = (status) => {
	switch (status) {
		case 0:
			return 'Pending';
		case 1:
			return 'Completed';
		case 2:
			return 'Failed';
		case 3:
			return 'Cancelled';
		default:
			return 'Unknown';
	}
};
</script>

<template>
	<BaseProfileLayout>
		<div class="profile-section-container">
			<span class="font-bold text-sm tracking-wider">
				{{ $t('Transaction History').toUpperCase() }}:
			</span>
		</div>
		<div class="profile-section-container mt-4">
			<DateFilters @selectFilter="handleDateFilterSelect" />
			<div class="mt-6">
				<BaseTable :columns="columns" :data="transactions" :loading="loading"
					@update:filters="handleFiltersUpdate">
					<template #transaction_type="{ value }">
						<span :class="getTransactionTypeColor(value)">
							{{ value.charAt(0).toUpperCase() + value.slice(1) }}
						</span>
					</template>
					<template #amount="{ value, row }">
						<span :class="getTransactionTypeColor(row.transaction_type)">
							{{ row.symbol || '' }}{{ value }}
						</span>
					</template>
					<template #type="{ value }">
						<span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full"
							:class="getPaymentMethodColor(value)">
							{{ value }}
						</span>
					</template>
					<template #status="{ value }">
						<span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full"
							:class="getStatusColor(value)">
							{{ formatStatus(value) }}
						</span>
					</template>
					<template #proof="{ row }">
						<div class="flex justify-center">
							<a v-if="row.proof" :href="`/storage/${row.proof}`" target="_blank"
								class="inline-flex items-center px-2 py-1 text-xs font-medium text-white bg-primary-600 rounded hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 transition-colors">
								<svg class="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
									<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
										d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
								</svg>
								Download
							</a>
							<span v-else class="text-gray-400 text-sm">-</span>
						</div>
					</template>
					<template #created_at="{ row }">
						<span class="text-gray-400">{{ row.dateHumanReadable }}</span>
					</template>
				</BaseTable>
			</div>
		</div>
	</BaseProfileLayout>
</template>