<script setup>
const props = defineProps(["title", "options"]);

const selectedProviders = defineModel("selectedProviders");
const emit = defineEmits(["searchGames"]);

const searchGames = () => {
	emit("searchGames");
};
</script>

<template>
    <div class="dropdown">
        <button class="dropdown-button">
            {{ $t(title) }}
        </button>

        <div class="dropdown-content">
            <template v-for="provider in options">
                <div class="flex items-center">
                    <input v-model="selectedProviders" @change="searchGames" :id="`checkbox-${provider}`"
                           type="checkbox" :value="provider"
                           class="w-4 h-4 bg-gray-100 border-gray-300 rounded focus:ring-[var(--ci-primary-color)] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">

                    <label for="checked-checkbox" class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                        {{ provider }}
                    </label>
                </div>
            </template>
        </div>
    </div>
</template>

<style scoped>
.dropdown {
    position: relative;
}

.dropdown-button {
    background-color: var(--ci-primary-color);
    padding: 0.3rem 0.75rem;
    font-size: 0.875rem;
    border-radius: 0.25rem;
    cursor: pointer;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: var(--background-color);
    border-radius: 0.25rem;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    padding: 0.3rem;
    z-index: 10;
    font-size: 0.875rem;
}

.dropdown:hover .dropdown-content {
    display: block;
}

.text-center span {
    color: var(--text-color);
}
</style>
