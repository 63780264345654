import { defineStore } from "pinia";
import HttpApi from "@/Services/HttpApi.js";

export const useMenuBarStore = defineStore("MenuBarStore", {
	state: () => ({
		menuItems: [
			{
				name: "profileWallet",
				icon: "fa-user",
				text: "My profile",
				action: "myProfile",
			},
			{
				name: "wallet",
				icon: "fa-wallet",
				text: "Balance Management",
				action: "wallet",
			},
			{
				name: "profileHistory",
				icon: "fa-clock-rotate-left",
				text: "Betting History",
				action: "profileHistory",
			},
			{
				name: "profileNotifications",
				icon: "fa-bell",
				text: "Notifications",
				badge: "0",
				action: "profileNotifications",
				route: "/profile/notifications",
			},
			{
				name: "profileAffiliate",
				icon: "fa-hands-helping",
				text: "Refer and Earn",
				badge: "5,00 R$",
				action: "profileAffiliate",
				route: "/profile/affiliate",
			},
		],

		profileItems: [
			{
				name: "accountDetails",
				icon: "fa-user",
				text: "Account Details",
				route: "/profile/account-details",
			},
			{
				name: "loginSecurity",
				icon: "fa-lock",
				text: "Login and Security",
				route: "/profile/login-security",
			},
			{
				name: "loginHistory",
				icon: "fa-history",
				text: "Login History",
				route: "/profile/login-history",
			},
			{
				name: "accountLimits",
				icon: "fa-chart-line",
				text: "Account Limits",
				route: "/profile/account-limits",
			},
			{
				name: "selfExclusion",
				icon: "fa-user-slash",
				text: "Self Exclusion",
				route: "/profile/self-exclusion",
			},
		],

		walletItems: [
			{
				name: "profileWallet",
				icon: "fa-wallet",
				text: "Portfolio",
				route: "/profile/wallet",
			},
			{
				name: "profileTransactionHistory",
				icon: "fa-history",
				text: "Transaction History",
				route: "/profile/transaction-history",
			},
		],

		historyItems: [
			{
				name: "casinoBetting",
				icon: "fa-gamepad",
				text: "Casino Betting",
				route: "/profile/casino-betting",
			},
			{
				name: "sportsBetting",
				icon: "fa-futbol",
				text: "Sports Betting",
				route: "/profile/sports-betting",
			},
		],

		activeMenu: "default",
		activeLinks: [],
	}),

	actions: {
		defaultMenu() {
			this.activeMenu = "default";
			this.activeLinks = this.menuItems;
		},
		myProfile() {
			this.activeMenu = "profile";
			this.activeLinks = this.profileItems;
		},
		wallet() {
			this.activeMenu = "wallet";
			this.activeLinks = this.walletItems;
		},
		profileHistory() {
			this.activeMenu = "history";
			this.activeLinks = this.historyItems;
		},
		profileNotifications() {
			this.activeMenu = "default";
			this.activeLinks = this.menuItems;
		},
		profileAffiliate() {
			this.activeMenu = false;
		},
		async fetchUnreadNotificationsCount() {
			try {
				const response = await HttpApi.get("/profile/unread-notifications");
				const notificationsItem = this.menuItems.find(item => item.name === "profileNotifications");
				if (notificationsItem) {
					notificationsItem.badge = response.data.unread_count.toString();
				}
			} catch (error) {
				console.error("Failed to fetch unread notifications count", error);
			}
		},
		setActiveMenu(menu) {
			this.activeMenu = menu;

			if (menu === "default") {
				this.activeLinks = this.menuItems;
			} else if (menu === "profile") {
				this.activeLinks = this.profileItems;
			} else if (menu === "wallet") {
				this.activeLinks = this.walletItems;
			} else if (menu === "history") {
				this.activeLinks = this.historyItems;
			}
		},
	},
});
