<script setup>
import { ref } from 'vue'
import { useToast } from 'vue-toastification'
import BaseProfileLayout from "@/Layouts/BaseProfileLayout.vue";
import NotificationsSection from "@/Components/Profile/Notifications/NotificationsSection.vue";
import HttpApi from "@/Services/HttpApi.js";

const toast = useToast()
const areAllNotificationsReadable = ref(false)

const markAllNotificationsAsRead = async () => {
    try {
        await HttpApi.post("profile/notifications/read-all");
        sessionStorage.setItem(
            "sessionMessage",
            "Notificaciones marcadas como leídas"
        );
      reloadPage();
    } catch (error) {
        toast.error('Falha ao marcar notificações como lidas');
    }
}

const reloadPage = () => {
    location.reload()
}
</script>

<template>
    <BaseProfileLayout>
        <div class="flex flex-col gap-4">
            <div class="profile-section-container flex justify-between items-center">
                <span class="font-bold text-sm">
                    {{ $t('Notificações').toUpperCase() }}:
                </span>
                <div class="right-container">
                    <button @click="reloadPage" class="btn-cab">
                        <svg height="1em" viewBox="0 0 512 512" width="1em" xmlns="http://www.w3.org/2000/svg" class="">
                            <path
                                d="M449.9 39.96l-48.5 48.53C362.5 53.19 311.4 32 256 32C161.5 32 78.59 92.34 49.58 182.2c-5.438 16.81 3.797 34.88 20.61 40.28c16.97 5.5 34.86-3.812 40.3-20.59C130.9 138.5 189.4 96 256 96c37.96 0 73 14.18 100.2 37.8L311.1 178C295.1 194.8 306.8 223.4 330.4 224h146.9C487.7 223.7 496 215.3 496 204.9V59.04C496 34.99 466.9 22.95 449.9 39.96z"
                                fill="currentColor"></path>
                            <path
                                d="M462.4 329.8C433.4 419.7 350.4 480 255.1 480c-55.41 0-106.5-21.19-145.4-56.49l-48.5 48.53C45.07 489 16 477 16 452.1V307.1C16 296.7 24.32 288.3 34.66 288h146.9c23.57 .5781 35.26 29.15 18.43 46l-44.18 44.2C183 401.8 218 416 256 416c66.58 0 125.1-42.53 145.5-105.8c5.422-16.78 23.36-26.03 40.3-20.59C458.6 294.1 467.9 313 462.4 329.8z"
                                fill="currentColor" opacity="0.4"></path>
                        </svg>
                    </button>
                    <button 
                        @click="markAllNotificationsAsRead"
                        class="check-all-button btn-cab"
                    >
                        <span>{{ $t('Mark all as read') }}</span>
                    </button>
                </div>
            </div>
            <NotificationsSection />
        </div>
    </BaseProfileLayout>
</template>
<style scoped>
.right-container {
    display: flex;
    gap: .5rem;
    justify-content: flex-end;
    margin-bottom: -.5rem;
    margin-left: auto;
    margin-top: -.5rem;
    max-height: 15rem
}

@media (min-width: 768px) {
    .right-container {
        gap: .75rem
    }
}

.right-container button {
    align-items: center;
    background-color: #fdffff1a;
    border-radius: .25rem;
    cursor: pointer;
    display: flex;
    font-size: .675rem;
    height: 1.5rem;
    justify-content: center;
    min-width: 1.5rem;
    width: 1.5rem;
    --tw-text-opacity: 1;
    color: rgb(255 255 255/var(--tw-text-opacity))
}

@media (min-width: 768px) {
    .right-container button {
        font-size: .75rem;
        line-height: 1rem
    }
}

.right-container button.check-all-button {
    border-radius: .25rem;
    cursor: pointer;
    display: none;
    font-size: .55rem;
    font-weight: 600;
    padding: .25rem .625rem;
    position: relative;
    text-align: left;
    text-overflow: ellipsis;
    width: auto;
    --tw-text-opacity: 1;
    color: rgb(255 255 255/var(--tw-text-opacity));
    outline: 2px solid transparent;
    outline-offset: 2px
}

@media (min-width: 368px) {
    .right-container button.check-all-button {
        display: flex
    }
}

@media (min-width: 1280px) {
    .right-container button.check-all-button {
        font-size: .6rem
    }
}
.right-container button.check-all-button.btn-cab:hover {
    opacity: .9
}
</style>

