<script setup>
import { ref, onMounted, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useAuthStore } from "@/Stores/Auth.js";
import { useModalStore } from "@/Stores/ModalStore.js";
import HttpApi from "@/Services/HttpApi.js";
import GameLayout from "@/Layouts/GameLayout.vue";
import LoadingComponent from "@/Components/UI/LoadingComponent.vue";
import GameHeader from "@/Components/Cassino/GamePlay/GameHeader.vue";
import GameScreen from "@/Components/Cassino/GamePlay/GameScreen.vue";
import GameFooter from "@/Components/Cassino/GamePlay/GameFooter.vue";
import GameTabs from "@/Components/Cassino/GamePlay/GameTabs.vue";
import RelatedGames from "@/Components/Cassino/GamePlay/RelatedGames.vue";

const isLoading = ref(true);
const game = ref(null);
const gameUrl = ref(null);
const token = ref(null);
const gameId = ref(null);
const showButton = ref(false);
const modalStore = useModalStore();
const authStore = useAuthStore();
const router = useRouter();
const modeMovie = ref(false);
const relatedGames = ref(null);
const isAuthenticated = computed(() => authStore.isAuth);

async function toggleFavorite() {
	return HttpApi.post(`games/favorite/${game.value.id}`, {})
		.then(() => getGame())
		.catch(() => {
			isLoading.value = false;
		});
}

async function toggleLike() {
	return HttpApi.post(`games/like/${game.value.id}`, {})
		.then(() => getGame())
		.catch(() => {
			isLoading.value = false;
		});
}

function openModal(url) {
	window.open(url);
}

async function getGame() {
	return await HttpApi.get(`games/single/${gameId.value}`)
		.then((response) => {
			if (response.data?.action === "deposit") {
				modalStore.openDepositModal();
			}

			game.value = response.data.game;
			gameUrl.value = response.data.gameUrl;
			token.value = response.data.token;
			isLoading.value = false;
		})
		.catch(() => {
			isLoading.value = false;
		});
}

async function getRelatedGames() {
	return await HttpApi.get(`games/related/${gameId.value}`)
		.then((response) => {
			relatedGames.value = response.data;
			isLoading.value = false;
		})
		.catch(() => {
			isLoading.value = false;
		});
}

onMounted(async () => {
	if (!isAuthenticated.value) {
		await router.push({ name: "login", params: { action: "openlogin" } });
		return;
	}

	try {
		const route = useRoute();
		gameId.value = route.params.id;
		await Promise.all([getGame(), getRelatedGames()]);
	} catch (error) {
		console.error(error);
	}
});
</script>

<template>
    <GameLayout>
        <LoadingComponent :isLoading="isLoading">
            <div class="text-center">
                <span>{{ $t('Loading game information') }}</span>
            </div>
        </LoadingComponent>

        <div v-if="!isLoading && game" :class="{ 'w-full': modeMovie, 'lg:w-2/3': !modeMovie }"
             class="mx-auto px-2 lg:px-4 py-2 lg:py-16 relative">
            <GameHeader :game="game"/>
            <GameScreen :game="game" :gameUrl="gameUrl" :showButton="showButton"/>
            <GameFooter :game="game" :toggleFavorite="toggleFavorite" :toggleLike="toggleLike"
                        @toggle-mode-movie="modeMovie = !modeMovie"
                        @togglefullscreen="openModal(gameUrl)"/>

            <RelatedGames :games="relatedGames"/>
        </div>
    </GameLayout>
</template>

<style scoped>
.game-screen {
    margin-top: 30px;
    width: 100%;
    min-height: 650px;
}
</style>
