<script setup>
import {ref, computed, onMounted} from "vue";
import {useAuthStore} from "@/Stores/Auth.js";
import {useSettingStore} from "@/Stores/SettingStore.js";
import Timer from "@/Components/Widgets/DepositWidget/Timer.vue";
import PaymentButton from "@/Components/Widgets/DepositWidget/PaymentButton.vue";
import DepositForm from "@/Components/Widgets/DepositWidget/DepositForm.vue";
import StripeDepositForm from "@/Components/Widgets/DepositWidget/StripeDepositForm.vue";
import StripeCheckoutDepositForm from "@/Components/Widgets/DepositWidget/StripeCheckoutDepositForm.vue";
import QRCodeDisplay from "@/Components/Widgets/DepositWidget/QRCodeDisplay.vue";
import HttpApi from "@/Services/HttpApi.js";
import {useToast} from "vue-toastification";
import {useDepositCardStore} from "@/Stores/DepositCardStore.js";

const authStore = useAuthStore();
const depositCardStore = useDepositCardStore();
const isAuthenticated = computed(() => authStore.isAuth);
const amountOptions = computed(() => depositCardStore.depositCards);
const loadingDepositCards = ref(false);
const paymentType = ref(null);
const showPixQRCode = ref(false);
const qrcodecopypast = ref("");
const setting = ref(null);
const wallet = ref(null);
const isLoadingWallet = ref(false);
const currency = ref(null);
const amount = ref(null);

const toast = useToast();

const setPaymentMethod = (type) => {
    paymentType.value = type;
};

const getWallet = async () => {
    isLoadingWallet.value = true;

    try {
        const response = await HttpApi.get("profile/wallet");
        wallet.value = response.data.wallet;
        currency.value = response.data.wallet.currency;
    } catch (error) {
        Object.entries(JSON.parse(error.request.responseText)).forEach(
            ([key, value]) => {
                toast.error(`${value}`);
            },
        );
    } finally {
        isLoadingWallet.value = false;
    }
};

const getSetting = () => {
    const settingStore = useSettingStore();
    const settingData = settingStore.setting;

    if (settingData) {
        setting.value = settingData;
        amount.value = settingData.max_deposit;

        if (paymentType.value === "stripe" && settingData.stripe_is_enable) {
        }
    }
};

const handleDepositSuccess = (depositData) => {
    toast.success("Depósito realizado com sucesso!");
    getWallet();
};

onMounted(async () => {
    if (isAuthenticated.value) {
        loadingDepositCards.value = true;
        await Promise.all([getWallet(), getSetting(), depositCardStore.fetchDepositCards()]);
        loadingDepositCards.value = false;
    }
});
</script>

<template>
    <div class="p-4 bg-gray-800/50">
        <div class="flex justify-between items-center text-white">
            <div class="text-sm">
                Realize seu primeiro depósito e ganhe até 80 giros no Fortune Tiger
            </div>
            <Timer :initialMinutes="8" :initialSeconds="10"/>
        </div>
    </div>

    <div class="grid grid-cols-2 gap-3 p-4" v-if="setting">
<!--        <PaymentButton v-if="setting.suitpay_is_enable" :paymentType="'pix'" :isActive="paymentType === 'pix'"-->
<!--                       imageSrc="/assets/images/pix.png" altText="PIX" @click="setPaymentMethod('pix')"/>-->

        <PaymentButton :paymentType="'stripe'" :isActive="paymentType === 'stripe'" imageSrc="/assets/images/pay.png"
                       altText="Stripe" @click="setPaymentMethod('stripe')" />

        <PaymentButton :paymentType="'checkout-stripe'" :isActive="paymentType === 'checkout-stripe'" imageSrc="/assets/images/check.png"
                       altText="Stripe" @click="setPaymentMethod('checkout-stripe')" />

<!--        <PaymentButton :paymentType="'stripe'"-->
<!--                       :isActive="paymentType === 'stripe'"-->
<!--                       @click="setPaymentMethod('stripe')">-->
<!--            <span class="flex items-center text-[#7055f7] font-bold gap-1 text-sm py-2 px-2">-->
<!--                <svg xmlns="http://www.w3.org/2000/svg" class="size-6 -mt-[0.3rem]" viewBox="0 0 32 32"><g fill="none"><path fill="#7055f7" d="M27.752 30H4.248A2.25 2.25 0 0 1 2 27.751V14.25A2.25 2.25 0 0 1 4.248 12h23.504A2.25 2.25 0 0 1 30 14.249V27.75A2.245 2.245 0 0 1 27.752 30"/><path fill="#8f78ff" d="M23.386 24H5.614A.617.617 0 0 1 5 23.384v-2.768c0-.333.272-.616.614-.616h17.772c.332 0 .614.273.614.616v2.778a.61.61 0 0 1-.614.606"/><path fill="#fff" d="M25.353 28h1.794a.85.85 0 0 0 .853-.853v-1.794a.85.85 0 0 0-.853-.853h-1.794a.85.85 0 0 0-.853.853v1.794a.86.86 0 0 0 .853.853"/><path fill="#fff" d="M30 15H2v3h28z"/><path fill="#fff" d="M6 22a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 6 22"/></g></svg>-->
<!--                Stripe One Click Pay-->
<!--            </span>-->
<!--        </PaymentButton>-->

<!--        <PaymentButton :paymentType="'checkout-stripe'"-->
<!--                       :isActive="paymentType === 'checkout-stripe'"-->
<!--                       @click="setPaymentMethod('checkout-stripe')">-->
<!--            <span class="flex items-center text-[#7055f7] font-bold gap-1 text-sm py-2 px-2">-->
<!--                <svg xmlns="http://www.w3.org/2000/svg" class="size-6" viewBox="0 0 48 48"><path fill="#7055f7" d="M6.66 43.44a18.21 1.56 0 1 0 36.42 0a18.21 1.56 0 1 0-36.42 0" opacity="0.15"/><path fill="#7055f7" d="M1.521 21.513L37.047 6.674l9.423 22.561l-35.525 14.84Z"/><path fill="#fff" d="M35.13 7.48L3.45 20.72a2.08 2.08 0 0 0-1.12 2.72l1.3 3.12a2.08 2.08 0 0 1 1.12-2.72L36.43 10.6a2.1 2.1 0 0 1 2.73 1.12L37.85 8.6a2.08 2.08 0 0 0-2.72-1.12"/><path fill="none" stroke="#7055f7" stroke-linecap="round" stroke-linejoin="round" d="M1.521 21.513L37.047 6.674l9.423 22.561l-35.525 14.84Z"/><path fill="#fff" stroke="#7055f7" stroke-linecap="round" stroke-linejoin="round" d="m3.537 26.317l35.526-14.84l1.803 4.32L5.341 30.634z"/><path fill="#fff" stroke="#7055f7" stroke-linecap="round" stroke-linejoin="round" d="m12.588 30.433l26.88-11.227l1.202 2.879l-26.88 11.227Z"/><path fill="#daedf7" stroke="#7055f7" stroke-linecap="round" stroke-linejoin="round" d="m35.23 26.61l6.237-2.605l1.604 3.838l-6.238 2.606Z"/><path fill="none" stroke="#7055f7" stroke-linecap="round" stroke-linejoin="round" d="m15.46 35.99l8.17-3.41m3.36-1.4l3.84-1.6"/></svg>-->
<!--                Stripe Checkout-->
<!--            </span>-->
<!--        </PaymentButton>-->
    </div>

    <DepositForm v-if="!showPixQRCode && paymentType === 'pix'" :setting="setting" :wallet="wallet"
                 :paymentType="paymentType"
                 :amount-options="amountOptions" :loading-deposit-cards="loadingDepositCards"
    />

    <StripeDepositForm v-if="paymentType === 'stripe'" :setting="setting" :wallet="wallet" :paymentType="paymentType"
                       @depositSuccess="handleDepositSuccess"
                       :amount-options="amountOptions" :loading-deposit-cards="loadingDepositCards"
    />

    <StripeCheckoutDepositForm v-if="paymentType === 'checkout-stripe'" :setting="setting" :wallet="wallet"
                               :paymentType="paymentType"
                               :amount-options="amountOptions" :loading-deposit-cards="loadingDepositCards"
                               @depositSuccess="handleDepositSuccess"/>

    <QRCodeDisplay v-if="showPixQRCode && wallet" :qrcodecopypast="qrcodecopypast" :amount="amount"
                   :currency="currency"/>
</template>
