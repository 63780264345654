import { defineStore } from "pinia";
import { trans } from "laravel-vue-i18n";

export const useBankingFieldsCountryStore = defineStore("BankingFieldsCountryStore", {
    state() {
        return {
            bankingFieldsCountry: {
                'BR': [
                    { name: 'bankName', label: trans('Bank Name'), type: 'text' },
                    { name: 'agencyNumber', label: trans('Agency Number'), type: 'text' },
                    { name: 'accountNumber', label: trans('Account Number'), type: 'text' },
                    { name: 'cpf', label: trans('CPF'), type: 'text' }
                ],
                'US': [
                    { name: 'bankName', label: trans('Bank Name'), type: 'text' },
                    { name: 'routingNumber', label: trans('Routing Number'), type: 'text' },
                    { name: 'accountNumber', label: trans('Account Number'), type: 'text' }
                ],
                'MX': [
                    { name: 'bankName', label: trans('Bank Name'), type: 'text' },
                    { name: 'clabe', label: trans('CLABE'), type: 'text' }
                ],
                'CO': [
                    { name: 'bankName', label: trans('Bank Name'), type: 'text' },
                    { name: 'accountNumber', label: trans('Account Number'), type: 'text' }
                ],
                'PE': [
                    { name: 'bankName', label: trans('Bank Name'), type: 'text' },
                    { name: 'accountNumber', label: trans('Account Number'), type: 'text' }
                ],
                'CL': [
                    { name: 'bankName', label: trans('Bank Name'), type: 'text' },
                    { name: 'accountNumber', label: trans('Account Number'), type: 'text' }
                ],
                'UY': [
                    { name: 'bankName', label: trans('Bank Name'), type: 'text' },
                    { name: 'accountNumber', label: trans('Account Number'), type: 'text' }
                ],
                'PY': [
                    { name: 'bankName', label: trans('Bank Name'), type: 'text' },
                    { name: 'accountNumber', label: trans('Account Number'), type: 'text' }
                ],
                'BO': [
                    { name: 'bankName', label: trans('Bank Name'), type: 'text' },
                    { name: 'accountNumber', label: trans('Account Number'), type: 'text' }
                ],
                'EC': [
                    { name: 'bankName', label: trans('Bank Name'), type: 'text' },
                    { name: 'accountNumber', label: trans('Account Number'), type: 'text' }
                ],
                'VE': [
                    { name: 'bankName', label: trans('Bank Name'), type: 'text' },
                    { name: 'accountNumber', label: trans('Account Number'), type: 'text' }
                ],
                'CA': [
                    { name: 'bankName', label: trans('Bank Name'), type: 'text' },
                    { name: 'transitNumber', label: trans('Transit Number'), type: 'text' },
                    { name: 'institutionNumber', label: trans('Institution Number'), type: 'text' },
                    { name: 'accountNumber', label: trans('Account Number'), type: 'text' }
                ],
                'GB': [
                    { name: 'bankName', label: trans('Bank Name'), type: 'text' },
                    { name: 'sortCode', label: trans('Sort Code'), type: 'text' },
                    { name: 'accountNumber', label: trans('Account Number'), type: 'text' }
                ],
                'AU': [
                    { name: 'bankName', label: trans('Bank Name'), type: 'text' },
                    { name: 'bsb', label: trans('BSB Number'), type: 'text' },
                    { name: 'accountNumber', label: trans('Account Number'), type: 'text' }
                ],
                'IN': [
                    { name: 'bankName', label: trans('Bank Name'), type: 'text' },
                    { name: 'ifsc', label: trans('IFSC Code'), type: 'text' },
                    { name: 'accountNumber', label: trans('Account Number'), type: 'text' }
                ],
                'JP': [
                    { name: 'bankName', label: trans('Bank Name'), type: 'text' },
                    { name: 'branchCode', label: trans('Branch Code'), type: 'text' },
                    { name: 'accountNumber', label: trans('Account Number'), type: 'text' }
                ],
                'ZA': [
                    { name: 'bankName', label: trans('Bank Name'), type: 'text' },
                    { name: 'branchCode', label: trans('Branch Code'), type: 'text' },
                    { name: 'accountNumber', label: trans('Account Number'), type: 'text' }
                ],
                'NG': [
                    { name: 'bankName', label: trans('Bank Name'), type: 'text' },
                    { name: 'accountNumber', label: trans('Account Number'), type: 'text' },
                    { name: 'bankVerificationNumber', label: trans('Bank Verification Number (BVN)'), type: 'text' }
                ],
                'EU': [
                    { name: 'bankName', label: trans('Bank Name'), type: 'text' },
                    { name: 'iban', label: trans('IBAN'), type: 'text' },
                    { name: 'swiftCode', label: trans('SWIFT Code'), type: 'text' }
                ],
                'GEN_SWIFT': [
                    { name: 'bankName', label: trans('Bank Name'), type: 'text' },
                    { name: 'swiftCode', label: trans('SWIFT Code'), type: 'text' }
                ],
                'GEN_IBAN': [
                    { name: 'bankName', label: trans('Bank Name'), type: 'text' },
                    { name: 'iban', label: trans('IBAN'), type: 'text' }
                ]
            },
            countryNames: {
                'BR': trans('Brazil'),
                'US': trans('United States'),
                'MX': trans('Mexico'),
                'CO': trans('Colombia'),
                'PE': trans('Peru'),
                'CL': trans('Chile'),
                'UY': trans('Uruguay'),
                'PY': trans('Paraguay'),
                'BO': trans('Bolivia'),
                'EC': trans('Ecuador'),
                'VE': trans('Venezuela'),
                'CA': trans('Canada'),
                'GB': trans('United Kingdom'),
                'AU': trans('Australia'),
                'IN': trans('India'),
                'JP': trans('Japan'),
                'ZA': trans('South Africa'),
                'NG': trans('Nigeria'),
                'EU': trans('European Union (SEPA)'),
                'GEN_SWIFT': trans('Generic SWIFT'),
                'GEN_IBAN': trans('Generic IBAN')
            }
        };
    }
});
