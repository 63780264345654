<script setup>
import { computed } from "vue";
const props = defineProps([
	"paymentType",
	"isActive",
	"imageSrc",
	"altText",
	"onClick",
]);

const buttonClass = computed(() => [
	"custom-payment-button",
	props.isActive ? "active-border" : "hover-border",
]);
</script>

<template>
    <button @click="onClick" :class="buttonClass">
        <img v-if="imageSrc" :src="imageSrc" :alt="altText" class="h-8 object-contain" />
        <span v-else>
            <slot></slot>
        </span>
    </button>
</template>

<style scoped>
.custom-payment-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    background-color: var(--ci-gray-dark);
    border-radius: 0.5rem;
    transition: background-color 0.3s ease, border 0.3s ease, box-shadow 0.3s ease;
}

.custom-payment-button:hover {
    border: 2px solid var(--ci-primary-color);
    box-shadow: 0px 0px 8px var(--ci-secondary-color);
}

.active-border {
    border: 2px solid var(--ci-secundary-color, #8a59c4);
    box-shadow: 0px 0px 8px var(--ci-secundary-color, #8a59c4);
}

.hover-border:hover {
    border: 2px solid var(--ci-primary-color, #720dfa);
}
</style>
