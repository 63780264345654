<script setup>
import {computed, onMounted, ref, watch} from "vue";
import {Modal} from "flowbite";
import NavMenu from "@/Components/Nav/NavMenu.vue";
import SearchMenu from "@/Components/Nav/SearchMenu.vue";
import LoginModal from "@/Components/AuthModals/LoginModal.vue";
import RegisterModal from "@/Components/AuthModals/RegisterModal.vue";
import ProfileModal from "@/Components/AuthModals/ProfileModal.vue";
import {searchGameStore} from "@/Stores/SearchGameStore.js";
import {sidebarStore} from "@/Stores/SideBarStore.js";
import HttpApi from "@/Services/HttpApi.js";
import TextTopBanner from "@/Components/UI/TextTopBanner.vue";
import {useAuthStore} from "@/Stores/Auth.js";
import {useToast} from "vue-toastification";
import {useRoute, useRouter} from "vue-router";

const sidebarMenuStore = sidebarStore();
const authStore = useAuthStore();
const modalAuth = ref(null);
const modalRegister = ref(null);
const modalProfile = ref(null);
const searchGameDataStore = ref(null);
const toast = useToast();
const isAuth = computed(() => authStore.isAuth);

const props = defineProps(["simple", "login"]);
const route = useRoute();
const router = useRouter();

watch(
    () => route.query.login,
    (login) => {
        if (login === "true") {
            loginToggle();
        }
    },
);

const loginToggle = (event) => {
    if (event !== undefined) {
        if (event.force && event.force === true) {
            modalAuth.value.hide();
            return
        }
    }

    if (!modalAuth.value.isHidden) {
        modalAuth.value.hide();
    } else {
        modalAuth.value.show();
    }
};

const registerToggle = () => {
    modalRegister.value.toggle();
};

const profileToggle = () => {
    modalProfile.value.toggle();
};

const logoutAccount = async () => {
    try {
        await HttpApi.post("auth/logout", {});
        await authStore.logout();
    } catch (error) {
        Object.entries(JSON.parse(error.request.responseText)).forEach(
            ([key, value]) => {
                toast.error(`${value}`);
            },
        );
    }
};

function toggleMenu() {
    sidebarMenuStore.setSidebarToogle();
}

function mountAuthModal() {
    modalAuth.value = new Modal(document.getElementById("modalElAuth"), {
        placement: "center",
        backdrop: "dynamic",
        backdropClasses:
            "bg-gray-700 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40",
        closable: false,
    });

    modalRegister.value = new Modal(document.getElementById("modalElRegister"), {
        placement: "center",
        backdrop: "dynamic",
        backdropClasses:
            "bg-gray-700 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40",
        closable: false,
    });

    modalAuth.value.hide();
}

onMounted(() => {
    searchGameDataStore.value = searchGameStore;

    modalProfile.value = new Modal(document.getElementById("modalProfileEl"), {
        placement: "center",
        backdrop: "dynamic",
        backdropClasses:
            "bg-gray-700 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40",
        closable: false,
    });

    if (!isAuth.value) {
        mountAuthModal();
    }

    if (props.login) {
        loginToggle();
    }
});
</script>

<template>
    <nav class="fixed navbar top-0 z-[100] w-full navtop-color border-none custom-box-shadow">
        <TextTopBanner/>

        <NavMenu :simple="props.simple"
                 @toggle-menu="toggleMenu"
                 @login-toggle="loginToggle"
                 @register-toggle="registerToggle"
                 @profile-toggle="profileToggle"
                 @logout-account="logoutAccount"
        />
        <SearchMenu/>
    </nav>

    <LoginModal
        v-if="!isAuth"
        @register-toggle="registerToggle"
        @login-toggle="loginToggle"
    />

    <RegisterModal
        v-if="!isAuth"
        @register-toggle="registerToggle"
        @login-toggle="loginToggle"
    />

    <ProfileModal @profile-toggle="profileToggle"/>
</template>
