<script setup>
import { useAuthStore } from "@/Stores/Auth.js";
import { computed } from "vue";
import { useToast } from "vue-toastification";
import HttpApi from "@/Services/HttpApi.js";
import { trans } from "laravel-vue-i18n";

const authStore = useAuthStore();
const userData = computed(() => authStore.user);
const toast = useToast();

const submitKyc = async () => {
    const documentUpload = document.getElementById('document-upload').files[0];
    const selfieUpload = document.getElementById('selfie-upload').files[0];

    if (!documentUpload || !selfieUpload) {
        toast.error(trans('Please upload both files'));
        return;
    }

    const formData = new FormData();
    formData.append('document', documentUpload);
    formData.append('selfie', selfieUpload);

    try {
        const response = await HttpApi.post('/profile/verify-kyc', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        toast.success(trans('KYC verification request submitted'));

        window.location.reload();
    } catch (error) {
        toast.error(trans('Error submitting KYC verification request'));
    }
};
</script>

<template>
    <section class="mb-6" v-if="!userData.kyc || userData.kyc.status === 'rejected'">
        <div class="flex items-center justify-between">
            <div class="flex items-center gap-2">
                <h2 class="text-lg font-semibold" style="color: var(--title-color)">
                    {{ $t("Document") }}
                </h2>
                <span v-if="!userData.kyc" class="px-3 py-1 text-xs font-semibold text-white rounded-full bg-red-500">
                    {{ $t("KYC Pending") }}
                </span>
                <span v-else class="px-3 py-1 text-xs font-semibold text-white rounded-full bg-red-500">
                    {{ $t("KYC Rejected") }}
                </span>
            </div>
        </div>
        <p class="mt-3 mb-3" style="color: var(--text-color)">
            <span v-if="!userData.kyc">
                {{ $t("Document not provided") }}
            </span>
            <span v-else>
                {{ $t("Your KYC verification has been rejected. Please re-upload the documents.") }}
            </span>
            <br/>
            <p style="color: var(--sub-text-color)">
                {{ $t("Verify your identity (KYC)") }}
            </p>
        </p>
        <div class="mt-6">
            <label for="document-upload" class="block text-sm font-medium text-white">
                {{ $t("Upload Identification Document") }}
            </label>
            <input id="document-upload" type="file" class="mt-1 block w-full" />
        </div>
        <div class="mt-6">
            <label for="selfie-upload" class="block text-sm font-medium text-white">
                {{ $t("Upload Selfie with Document") }}
            </label>
            <input id="selfie-upload" type="file" class="mt-1 block w-full" />
        </div>
        <button @click="submitKyc" class="bg-[var(--ci-primary-color)] py-2 px-4 rounded-lg text-bold flex gap-2 items-center mt-4">
            {{ $t("Submit") }}
        </button>
        <hr class="my-4 border-t" style="border-color: var(--sub-text-color)" />
    </section>
    <section class="mb-6" v-else-if="userData.kyc.status === 'pending'">
        <div class="flex items-center justify-between">
            <div class="flex items-center gap-2">
                <h2 class="text-lg font-semibold" style="color: var(--title-color)">
                    {{ $t("Document") }}
                </h2>
                <span class="px-3 py-1 text-xs font-semibold text-white rounded-full bg-yellow-500">
                    {{ $t("KYC Pending") }}
                </span>
            </div>
        </div>
        <p class="mt-3 mb-3" style="color: var(--text-color)">
            {{ $t("Your KYC verification is pending. Please wait for approval.") }}
        </p>
        <hr class="my-4 border-t" style="border-color: var(--sub-text-color)" />
    </section>
    <section class="mb-6" v-else-if="userData.kyc.status === 'approved'">
        <div class="flex items-center justify-between">
            <div class="flex items-center gap-2">
                <h2 class="text-lg font-semibold" style="color: var(--title-color)">
                    {{ $t("Document") }}
                </h2>
                <span class="px-3 py-1 text-xs font-semibold text-white rounded-full bg-green-500">
                    {{ $t("KYC Approved") }}
                </span>
            </div>
        </div>
        <p class="mt-3 mb-3" style="color: var(--text-color)">
            {{ $t("Your identity has been verified successfully.") }}
        </p>
        <hr class="my-4 border-t" style="border-color: var(--sub-text-color)" />
    </section>
</template>
