<script setup>
import BaseLayout from "@/Layouts/BaseLayout.vue";
import {useSettingStore} from "@/Stores/SettingStore.js";
import {onMounted, ref} from "vue";
import {sidebarStore} from "@/Stores/SideBarStore.js";

const settingStore = useSettingStore();
const sidebarMenuStore = sidebarStore();

const softwareName = ref(null);

onMounted(async () => {
    const setting = JSON.parse(localStorage.getItem("setting"));
    console.log(setting);
    softwareName.value = setting.software_name;
    console.log(softwareName.value);
});
</script>

<template>
    <BaseLayout>
        <div class="container mx-auto pt-14 px-[13rem]" id="termsContainer">
            <h1>Termos de Serviço - {{ softwareName }}</h1>
            <p>Bem-vindo ao {{ softwareName }}, operando em <a href="https://flamix.gg">flamix.gg</a>. Ao acessar ou usar nossos serviços, você concorda em cumprir estes Termos de Serviço. Leia-os atentamente.</p>

            <h2>1. Termos Gerais</h2>
            <p>1.1. O {{ softwareName }} opera globalmente e está acessível a partir de várias jurisdições. No entanto, não oferecemos serviços para residentes ou cidadãos do Brasil.</p>
            <p>1.2. Ao usar o {{ softwareName }}, você confirma que é maior de idade em sua jurisdição para participar de jogos online e que é legal para você fazê-lo.</p>
            <p>1.3. O {{ softwareName }} reserva-se o direito de modificar estes termos a qualquer momento. As alterações serão comunicadas por meio de atualizações em nosso site.</p>

            <h2>2. Depósitos e Troca de Moeda</h2>
            <p>2.1. Os depósitos feitos no {{ softwareName }} são uma troca direta de moeda por créditos de jogo e não são reembolsáveis.</p>
            <p>2.2. Ao depositar fundos, você reconhece e aceita que essas transações são finais e não estão sujeitas a devoluções ou reembolsos sob qualquer circunstância.</p>
            <p>2.3. Você assume total responsabilidade por garantir que todos os detalhes do depósito estejam corretos antes de prosseguir.</p>

            <h2>3. Saques</h2>
            <p>3.1. Os saques são processados dentro de 24 horas a partir da solicitação do cliente, desde que todas as condições sejam atendidas.</p>
            <p>3.2. A verificação de Conheça Seu Cliente (KYC) é obrigatória para processar saques. Isso inclui o fornecimento de identificação válida e quaisquer documentos adicionais solicitados pelo {{ softwareName }}.</p>
            <p>3.3. As solicitações de saque só serão processadas após a conclusão bem-sucedida do processo de KYC.</p>

            <h2>4. Verificação de Conta (KYC)</h2>
            <p>4.1. A verificação de KYC é necessária apenas para saques e é um processo único, a menos que os detalhes da sua conta mudem.</p>
            <p>4.2. O não cumprimento das solicitações de KYC pode resultar em atrasos ou recusa de solicitações de saque.</p>
            <p>4.3. Ao aceitar estes termos, você autoriza o {{ softwareName }} a utilizar serviços de terceiros para verificação de identidade.</p>

            <h2>5. Responsabilidades do Usuário</h2>
            <p>5.1. Os usuários são os únicos responsáveis por manter a confidencialidade dos detalhes de sua conta.</p>
            <p>5.2. Qualquer uso não autorizado de sua conta deve ser informado ao {{ softwareName }} imediatamente.</p>
            <p>5.3. Envolver-se em atividades fraudulentas, incluindo, mas não se limitando a conluio, uso de bots ou outras táticas manipuladoras, é estritamente proibido e resultará na suspensão ou encerramento da conta.</p>

            <h2>6. Limitação de Responsabilidade</h2>
            <p>6.1. O {{ softwareName }} não se responsabiliza por perdas decorrentes do uso indevido de sua conta, problemas técnicos ou acesso não autorizado.</p>
            <p>6.2. Não garantimos operação ininterrupta ou sem erros de nossa plataforma.</p>
            <p>6.3. A responsabilidade do {{ softwareName }} é limitada ao valor dos fundos em sua conta no momento do incidente.</p>

            <h2>7. Lei Aplicável</h2>
            <p>7.1. Estes Termos de Serviço são regidos pelas leis da jurisdição onde o {{ softwareName }} opera.</p>
            <p>7.2. Quaisquer disputas decorrentes destes termos serão resolvidas por meio de arbitragem vinculativa em um local determinado pelo {{ softwareName }}.</p>
        </div>
    </BaseLayout>
</template>

<style scoped>
#termsContainer h1 {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 1rem;
}

#termsContainer h2 {
    font-size: 1.0rem;
    font-weight: 600;
    margin-top: 2rem;
    margin-bottom: 1rem;
}

#termsContainer p {
    font-size: 1.05rem;
    margin-bottom: 1rem;
}
</style>
