<script setup>
import {reactive, ref, watch, onMounted} from 'vue';
import {useBankingFieldsCountryStore} from "@/Stores/BankingFieldsCountry.js";
import {useToast} from "vue-toastification";
import {trans} from "laravel-vue-i18n";
import HttpApi from "@/Services/HttpApi.js";

const props = defineProps(['bankingDetails']);
const bankingFieldsCountryStore = useBankingFieldsCountryStore();
const selectedCountry = ref(props.bankingDetails ? props.bankingDetails.country : '');
const bankingFields = ref([]);
const formData = reactive({});
const titularName = ref(props.bankingDetails ? props.bankingDetails.titular_name : '');
const toast = useToast();

onMounted(() => {
    if (props.bankingDetails && props.bankingDetails.details) {
        Object.assign(formData, props.bankingDetails.details);
    }
});

watch(selectedCountry, (newCountry) => {
    bankingFields.value = bankingFieldsCountryStore.bankingFieldsCountry[newCountry] || [];

    bankingFields.value.forEach(field => {
        if (!formData[field.name]) {
            formData[field.name] = ''; // Initialize formData with empty strings for each field if not already set
        }
    });
}, {immediate: true});

const submitForm = async () => {
    try {
        const bankingData = {
            country: selectedCountry.value,
            titular_name: titularName.value,
            details: formData
        };

        const response = await HttpApi.post('/profile/update-banking-details', bankingData);

        if(response.status === 200) {
            window.location.reload();
        } else {
            toast.error(trans('Error submitting banking details, try again later, if the error persists, please contact support.'));
        }
    } catch (error) {
        toast.error(trans('Error submitting banking details, try again later, if the error persists, please contact support.'));
    }
};
</script>

<template>
    <section class="mb-6">
        <div class="flex items-center justify-between">
            <div class="flex items-center gap-2">
                <h2 class="text-lg font-semibold" style="color: var(--title-color)">
                    {{ $t("Banking Details") }}
                </h2>
            </div>
        </div>
        <div class="mb-4 mt-4">
            <label for="titularNameField" class="label">
                {{ $t('Titular Name') }}
            </label>
            <input
                id="titularNameField"
                name="titularName"
                type="text"
                v-model="titularName"
                class="input-group !pl-0"
            />
        </div>
        <div class="mb-4 mt-4">
            <label for="country" class="label">
                {{ $t("Select Country") }}
            </label>
            <select id="country" v-model="selectedCountry" class="input-group !pl-0">
                <option v-for="(fields, country) in bankingFieldsCountryStore.bankingFieldsCountry" :key="country"
                        :value="country">
                    {{ bankingFieldsCountryStore.countryNames[country] }} ({{ country }})
                </option>
            </select>
        </div>
        <div v-for="field in bankingFields" :key="field.name" class="mb-4">
            <label :for="field.name" class="label">
                {{ $t(field.label) }}
            </label>
            <input
                :id="field.name"
                :name="field.name"
                :type="field.type"
                v-model="formData[field.name]"
                class="input-group !pl-0"
            />
        </div>
        <button @click="submitForm"
                class="bg-[var(--ci-primary-color)] py-2 px-4 rounded-lg text-bold flex gap-2 items-center mt-4">
            {{ $t("Submit") }}
        </button>
        <hr
            class="my-4 border-t"
            style="border-color: var(--sub-text-color)"
        />
    </section>
</template>

<style scoped>
/* Adicione estilos conforme necessário */
</style>
