<script setup>
import { useRouter } from "vue-router";
import { breakpointsTailwind, useBreakpoints } from "@vueuse/core";
import { ref } from "vue";

const props = defineProps(["index", "game"]);
const router = useRouter();
const reactiveBreakpoints = useBreakpoints(breakpointsTailwind);
const isMobile = reactiveBreakpoints.smaller("md");

const isDragging = ref(false);
const startX = ref(0);
const startY = ref(0);
const threshold = 5;
const showButton = ref(false);

const onInteractionEnd = () => {
	setTimeout(() => {
		isDragging.value = false;
	}, 0);
};

const onMouseDown = (e) => {
	isDragging.value = false;
	startX.value = e.clientX;
	startY.value = e.clientY;
};

const onMouseMove = (e) => {
	const deltaX = Math.abs(e.clientX - startX.value);
	const deltaY = Math.abs(e.clientY - startY.value);
	if (deltaX > threshold || deltaY > threshold) {
		isDragging.value = true;
	}
};

const onMouseUp = () => {
	onInteractionEnd();
};

const onMouseLeave = () => {
	isDragging.value = false;
	showButton.value = false;
};

const onTouchStart = (e) => {
	isDragging.value = false;
	const touch = e.touches[0];
	startX.value = touch.clientX;
	startY.value = touch.clientY;
};

const onTouchMove = (e) => {
	const touch = e.touches[0];
	const deltaX = Math.abs(touch.clientX - startX.value);
	const deltaY = Math.abs(touch.clientY - startY.value);
	if (deltaX > threshold || deltaY > threshold) {
		isDragging.value = true;
	}
};

const onTouchEnd = () => {
	onInteractionEnd();
};

const handleClick = () => {
	if (!isDragging.value) {
		router.push({
			name: "casinoPlayPage",
			params: { id: props.game.id, slug: props.game.game_code },
		});
	}
};

const onMouseEnter = () => {
	showButton.value = true;
};
</script>

<template>
    <div
        class="relative text-gray-700 w-full h-auto mr-4 cursor-pointer"
        :style="{height: 'auto'}"
        @mousedown="onMouseDown"
        @mousemove="onMouseMove"
        @mouseup="onMouseUp"
        @mouseleave="onMouseLeave"
        @mouseenter="onMouseEnter"
        @touchstart="onTouchStart"
        @touchmove="onTouchMove"
        @touchend="onTouchEnd"
        @click.prevent="handleClick"
    >
        <img
            :src="game.distribution === 'kagaming' ? game.cover : '/storage/' + game.cover"
            alt=""
            class="w-full"
            :class="{ 'h-48': !isMobile }"
            :style="{ borderRadius: '.375rem', height: isMobile ? '9.525rem' : '' }"
        >
        <div
            v-if="showButton"
            class="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white text-lg"
        >
            <button class="bg-[var(--ci-primary-color)] py-2 px-4 rounded-lg text-bold flex gap-2 items-center">
                <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4" viewBox="0 0 24 24">
                    <path fill="currentColor" fill-opacity="0" stroke="currentColor" stroke-dasharray="40"
                          stroke-dashoffset="40" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="M8 6l10 6l-10 6Z">
                        <animate fill="freeze" attributeName="fill-opacity" begin="0.5s" dur="0.5s" values="0;1"/>
                        <animate fill="freeze" attributeName="stroke-dashoffset" dur="0.5s" values="40;0"/>
                    </path>
                </svg>

                {{ $t('Play') }}
            </button>
        </div>
    </div>
</template>

<style scoped>
.user-select-none {
    user-select: none;
}
</style>
