<script setup>
import {ref, computed, onMounted} from "vue";
import {useAuthStore} from "@/Stores/Auth.js";
import {useSettingStore} from "@/Stores/SettingStore.js";
import {useToast} from "vue-toastification";
import HttpApi from "@/Services/HttpApi.js";
import Timer from "@/Components/Widgets/DepositWidget/Timer.vue";
import WithdrawalForm from "@/Components/Widgets/WithdrawalWidget/WithdrawalForm.vue";
import {trans} from "laravel-vue-i18n";

const authStore = useAuthStore();
const isAuthenticated = computed(() => authStore.isAuth);
const setting = ref(null);
const wallet = ref(null);
const isLoadingWallet = ref(false);
const currency = ref(null);
const toast = useToast();

const getWallet = async () => {
    isLoadingWallet.value = true;
    try {
        const response = await HttpApi.get("profile/wallet");
        wallet.value = response.data.wallet;
        currency.value = response.data.wallet.currency;
    } catch (error) {
        Object.entries(JSON.parse(error.request.responseText)).forEach(
            ([key, value]) => {
                toast.error(`${value}`);
            }
        );
    } finally {
        isLoadingWallet.value = false;
    }
};

const getSetting = () => {
    const settingStore = useSettingStore();
    const settingData = settingStore.setting;
    if (settingData) {
        setting.value = settingData;
    }
};

const handleWithdrawalSuccess = () => {
    toast.success(trans("Solicitação de saque realizada com sucesso!"));
    getWallet();
};

onMounted(async () => {
    if (isAuthenticated.value) {
        await Promise.all([getWallet(), getSetting()]);
    }
});
</script>

<template>
    <div>
        <div class="p-4 bg-gray-800/50">
            <div class="flex justify-between items-center text-white">
                <div class="text-sm">
                    {{ $t('Request your withdrawal now') }}
                </div>
                <Timer :initialMinutes="30" :initialSeconds="0"/>
            </div>
        </div>

        <div class="p-4">
            <div v-if="setting && wallet" class="bg-gray-800/30 rounded-lg p-4">
                <div class="mb-4">
                    <h3 class="text-lg font-semibold text-white">
                        {{ $t('Available Balance') }}
                    </h3>
                    <p class="text-2xl font-bold text-[var(--ci-primary-color)]">
                        {{ wallet.symbol }} {{ parseFloat(wallet.balance).toFixed(2) }}
                    </p>
                </div>

                <WithdrawalForm
                    :setting="setting"
                    :wallet="wallet"
                    @withdrawalSuccess="handleWithdrawalSuccess"
                />
            </div>

            <div v-else class="flex justify-center p-8">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-8 h-8 animate-spin"
                    viewBox="0 0 24 24"
                >
                    <path
                        fill="currentColor"
                        d="M12 21a9 9 0 1 1 6.18-15.55a.75.75 0 0 1 0 1.06a.74.74 0 0 1-1.06 0A7.51 7.51 0 1 0 19.5 12a.75.75 0 0 1 1.5 0a9 9 0 0 1-9 9"
                    />
                </svg>
            </div>
        </div>
    </div>
</template>
