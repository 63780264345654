<script setup>
import { ref, onMounted } from "vue";
import BaseProfileLayout from "@/Layouts/BaseProfileLayout.vue";
import BaseTable from "@/Layouts/BaseTable.vue";
import HttpApi from "@/Services/HttpApi.js";
import { useToast } from "vue-toastification";
import { useMenuBarStore } from "@/Stores/MenuBarStore.js";

const loading = ref(false);
const notifications = ref({ data: [], meta: {} });
const tableFilters = ref({});

const columns = [
    {
        key: "type",
        label: "Type",
        sortable: true,
        searchable: true,
    },
    {
        key: "data",
        label: "Details",
        sortable: false,
        searchable: true,
    },
    {
        key: "read_at",
        label: "Status",
        sortable: true,
        searchable: false,
    },
    {
        key: "created_at",
        label: "Date",
        sortable: true,
        searchable: false,
    }
];

const fetchNotifications = async () => {
    loading.value = true;
    const _toast = useToast();
    try {
        const response = await HttpApi.get("/profile/notifications", {
            params: {
                ...tableFilters.value,
                page: tableFilters.value?.page || 1,
                perPage: tableFilters.value?.perPage || 10,
            },
        });
        notifications.value = response.data;
    } catch (error) {
        _toast.error('Failed to load notifications');
    } finally {
        loading.value = false;
    }
};

const handleFiltersUpdate = (filters) => {
    tableFilters.value = filters;
    fetchNotifications();
};

const getNotificationTypeColor = (type) => {
    switch (type) {
        case 'withdrawal_status_update':
            return 'bg-blue-900 text-blue-200';
        default:
            return 'bg-gray-900 text-gray-200';
    }
};

const getNotificationType = (type) => {
    switch (type) {
        case 'withdrawal_status_update':
            return 'Withdrawal';
        default:
            return 'Unknown';
    }
};

const getReadStatusColor = (readAt) => {
    return readAt 
        ? 'bg-green-900 text-green-200'
        : 'bg-yellow-900 text-yellow-200';
};

onMounted(() => {
    useMenuBarStore().setActiveMenu("notifications");
    fetchNotifications();
});
</script>

<template>
    <div class="mt-6">
        <BaseTable 
            :columns="columns" 
            :data="notifications" 
            :loading="loading"
            @update:filters="handleFiltersUpdate"
        >
            <template #type="{ value }">
                <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full"
                    :class="getNotificationTypeColor(value)">
                    {{ getNotificationType(value) }}
                </span>
            </template>

            <template #data="{ value }">
                <div class="text-sm text-gray-300">
                    <span class="text-gray-200">
                        {{ value.message }}
                    </span>
                </div>
            </template>

            <template #read_at="{ value }">
                <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full"
                    :class="getReadStatusColor(value)">
                    {{ value ? 'Read' : 'Unread' }}
                </span>
            </template>

            <template #created_at="{ row }">
                <span class="text-gray-400">
                    {{ row.dateHumanReadable }}
                </span>
            </template>
        </BaseTable>
    </div>
</template>