<script setup>
import { computed, ref } from "vue";
import { useToast } from "vue-toastification";
import HttpApi from "@/Services/HttpApi.js";
import { useAuthStore } from "@/Stores/Auth.js";
import LoadingSpinner from "@/Components/UI/LoadingSpinner.vue";
import { useBannerStore } from "@/Stores/BannerStore.js";
import SocialAuthButtons from "@/Components/Auth/SocialAuthButtons.vue";
import { useSettingStore } from "@/Stores/SettingStore.js";
import { trans } from "laravel-vue-i18n";

const emit = defineEmits(["register-toggle", "login-toggle"]);

const bannerStore = useBannerStore();
const settingStore = useSettingStore();

const isLoadingLogin = ref(false);
const showPassword = ref(false);
const showForgotPasswordModal = ref(false);

const setting = computed(() => settingStore.setting);
const banner = computed(() => bannerStore.loginBanner);

const loginForm = ref({
    email: "",
    password: "",
});

const forgotPasswordForm = ref({
    email: "",
});

const authStore = useAuthStore();
const toast = useToast();

const loginSubmit = async () => {
    isLoadingLogin.value = true;
    try {
        const response = await HttpApi.post("auth/login", {
            email: loginForm.value.email,
            password: loginForm.value.password,
            device: navigator.userAgent,
        });

        authStore.setToken(response.data.access_token);
        authStore.setUser(response.data.user);
        authStore.setIsAuth(true);
        loginForm.value = { email: "", password: "" };
        emit("login-toggle");
        sessionStorage.setItem(
            "sessionMessage",
            "¡Has iniciado sesión exitosamente!"
        );
        location.reload();
    } catch (error) {
        if (error.request && error.request.responseText) {
            const errorData = JSON.parse(error.request.responseText);
            if (errorData) {
                Object.entries(errorData).forEach(([key, value]) => {
                    toast.error(`${value}`);
                });
            } else {
                toast.error("Ocurrió un error desconocido.");
            }
        } else {
            toast.error("Error de red. Por favor, verifica tu conexión.");
        }
    } finally {
        isLoadingLogin.value = false;
    }
};

const togglePasswordVisibility = () => {
    showPassword.value = !showPassword.value;
};

const openForgotPasswordModal = () => {
    showForgotPasswordModal.value = true;
};

const closeForgotPasswordModal = () => {
    showForgotPasswordModal.value = false;
};

const sendPasswordRecovery = async () => {
    try {
        await HttpApi.post("auth/forgot-password", forgotPasswordForm.value);
        toast.success("¡Correo de recuperación enviado!");
        forgotPasswordForm.value.email = "";
        closeForgotPasswordModal();
    } catch (error) {
        toast.error("Ocurrió un error al enviar el correo de recuperación.");
    }
};

const registerToggle = () => {
    emit("login-toggle");
    emit("register-toggle");
};

const handleClickOutside = (event) => {
    const modalContent = document.getElementById("loginModalContent");
    if (!modalContent.contains(event.target)) {
        emit("login-toggle");
    }
};

const handleClickOutsideForgotPassword = (event) => {
    const modalContent = document.getElementById("forgotPasswordModalContent");
    if (!modalContent.contains(event.target)) {
        closeForgotPasswordModal();
    }
};
const closeModal = () => {
    emit("login-toggle", {force: true});
};
</script>

<template>
    <div
        id="modalElAuth"
        tabindex="-1"
        aria-hidden="true"
        class="fixed inset-0 z-[101] flex items-center justify-center overflow-y-auto bg-black bg-opacity-20"
        v-show="!showForgotPasswordModal"
        @click="handleClickOutside"
    >
        <div
            class="relative w-full max-w-lg bg-base md:rounded-lg shadow-lg mx-auto max-h-[calc(100dvh)]"
        >
            <div id="loginModalContent" class="relative">
                <img
                    :src="`/storage` + banner.image"
                    alt="Banner"
                    class="w-full h-40 object-cover rounded-t-lg"
                />
                <button
                    @click="closeModal"
                    class="absolute top-2 right-2 text-white text-2xl z-10"
                    style="padding-top: env(safe-area-inset-top)"
                >
                    <i class="fa-solid fa-xmark"></i>
                </button>
            </div>
            <div class="p-5 overflow-y-auto hide-scrollbar">
                <div class="mb-4 flex justify-center">
                    <img
                        :src="`/storage/` + setting.software_logo_black"
                        class="h-8 object-contain"
                        :alt="setting.software_name"
                    />
                </div>

                <form @submit.prevent="loginSubmit" method="post">
                    <div class="relative mb-3">
                        <div
                            class="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none"
                        >
                            <i
                                class="fa-regular fa-envelope text-success-emphasis"
                            ></i>
                        </div>
                        <input
                            required
                            type="email"
                            v-model="loginForm.email"
                            name="email"
                            class="input-group"
                            :placeholder="trans('Enter your email')"
                        />
                    </div>
                    <div class="relative mb-3">
                        <div
                            class="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none"
                        >
                            <i
                                class="fa-regular fa-lock text-success-emphasis"
                            ></i>
                        </div>
                        <input
                            required
                            :type="showPassword ? 'text' : 'password'"
                            v-model="loginForm.password"
                            name="password"
                            class="input-group pr-[40px]"
                            :placeholder="trans('Enter your password')"
                        />
                        <div
                            class="absolute inset-y-0 right-0 flex items-center pr-3.5"
                        >
                            <button
                                type="button"
                                @click="togglePasswordVisibility"
                                class="focus:outline-none"
                            >
                                <i
                                    :class="
                                        showPassword
                                            ? 'fa-regular fa-eye-slash'
                                            : 'fa-regular fa-eye'
                                    "
                                ></i>
                            </button>
                        </div>
                    </div>
                    <div class="flex justify-end items-center mb-6">
                        <a
                            href="#"
                            class="secondary-text text-sm"
                            @click.prevent="openForgotPasswordModal"
                        >
                            {{ $t("Forgot your password?") }}
                        </a>
                    </div>
                    <div class="mt-5 w-full">
                        <button
                            type="submit"
                            class="ui-button-blue rounded w-full mb-3"
                            :disabled="isLoadingLogin"
                        >
                            <span v-if="isLoadingLogin">
                                <LoadingSpinner /> {{ $t("Signing in...") }}
                            </span>
                            <span v-else>
                                {{ $t("Log in") }}
                            </span>
                        </button>
                    </div>
                </form>
                <div class="flex items-center justify-center mb-6">
                    <hr class="w-1/4 border-gray-300" />
                    <span class="px-2 text-gray-500">
                        {{ $t("Sign in with:") }}
                    </span>
                    <hr class="w-1/4 border-gray-300" />
                </div>
                <SocialAuthButtons />
                <div class="text-center mt-4">
                    <p class="text-sm text-gray-500">
                        {{ $t("Don't have an account?") }}
                        <a
                            href="#"
                            class="secondary-text"
                            @click.prevent="registerToggle"
                        >
                            <strong>{{ $t("Sign up") }}</strong>
                        </a>
                    </p>
                </div>
            </div>
        </div>
    </div>

    <div
        id="forgotPasswordModal"
        v-if="showForgotPasswordModal"
        class="fixed inset-0 z-[102] flex items-center justify-center overflow-y-auto md:bg-black md:bg-opacity-20"
        @click="handleClickOutsideForgotPassword"
    >
        <div
            class="min-h-screen w-full flex items-center justify-center md:px-4 md:py-6"
        >
            <div
                id="forgotPasswordModalContent"
                class="relative w-full h-screen md:h-auto md:max-w-md bg-base md:rounded-lg md:shadow-lg mx-auto md:mt-20"
                @click.stop
            >
                <div class="relative">
                    <img
                        :src="`/storage` + banner.image"
                        alt="Banner"
                        class="w-full h-40 object-cover md:rounded-t-lg custom-banner-border"
                    />
                    <button
                        @click="closeForgotPasswordModal"
                        class="absolute top-2 right-2 text-white text-2xl"
                    >
                        <i class="fa-solid fa-xmark"></i>
                    </button>
                </div>

                <div
                    class="p-5 md:max-h-[calc(100dvh-4rem)] overflow-y-auto hide-scrollbar"
                >
                    <div class="mb-6 flex justify-center">
                        <img
                            :src="`/storage/` + setting.software_logo_black"
                            class="h-8 object-contain"
                            :alt="setting.software_name"
                        />
                    </div>
                    <form
                        @submit.prevent="sendPasswordRecovery"
                        method="post"
                        action=""
                    >
                        <div class="relative mb-6">
                            <div
                                class="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none"
                            >
                                <i
                                    class="fa-regular fa-envelope text-success-emphasis"
                                ></i>
                            </div>
                            <input
                                required
                                type="email"
                                v-model="forgotPasswordForm.email"
                                name="email"
                                class="input-group"
                                :placeholder="$t('Enter your email address')"
                            />
                        </div>
                        <div class="mt-5 w-full">
                            <button
                                type="submit"
                                class="ui-button-blue rounded w-full mb-3"
                            >
                                {{ $t("Send Recovery Email") }}
                            </button>
                        </div>
                    </form>
                    <p
                        class="cursor-pointer text-center mt-4 secondary-text"
                        @click="closeForgotPasswordModal"
                    >
                        {{ $t("Already have an account? Sign in") }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
@media (max-width: 768px) {
    #loginModalContent {
        max-height: 100vh;
        border-radius: 0;
    }

    .rounded-t-lg {
        border-radius: 0;
    }
}
body.modal-open {
    overflow: hidden;
}

::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 4px;
}

::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 0.3);
}

@media (max-width: 768px) {
    #modalElAuth,
    #forgotPasswordModal {
        background: none;
    }

    .bg-base {
        border-radius: 0;
    }

    .overflow-y-auto {
        -webkit-overflow-scrolling: touch;
    }

    #loginModalContent {
        padding-top: env(safe-area-inset-top);
        padding-bottom: env(safe-area-inset-bottom);
    }

    img {
        padding-top: env(safe-area-inset-top);
    }
}

body.modal-open {
    overflow: hidden;
}

::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 4px;
}

::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 0.3);
}

@supports (-webkit-touch-callout: none) {
    .overflow-y-auto {
        -webkit-overflow-scrolling: touch;
    }
}

.d-flex {
    display: flex;
}

.hide-scrollbar {
    -ms-overflow-style: none !important; /* IE and Edge */
    scrollbar-width: none !important; /* Firefox */
}

.hide-scrollbar::-webkit-scrollbar {
    display: none !important; /* Chrome, Safari, and Opera */
}
</style>
