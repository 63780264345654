<script setup>
import { computed, ref, watch, onMounted } from "vue";
import { useAuthStore } from "@/Stores/Auth.js";
import { useSettingStore } from "@/Stores/SettingStore.js";
import { useModalStore } from "@/Stores/ModalStore.js";
import { sidebarStore } from "@/Stores/SideBarStore.js";
import AuthButtons from "@/Components/Nav/NavMenu/AuthButtons.vue";
import UserMenu from "@/Components/Nav/NavMenu/UserMenu.vue";
import MakeDeposit from "@/Components/UI/MakeDeposit.vue";
import WalletBalance from "@/Components/UI/WalletBalance.vue";
import WithdrawalWidget from "@/Components/Widgets/WithdrawalWidget.vue";
import NavigationLinks from "@/Components/Nav/NavMenu/NavigationLinks.vue";
import MissionButton from "@/Components/Nav/NavMenu/MissionButton.vue";
import ApplicationLogo from "@/Components/Nav/NavMenu/ApplicationLogo.vue";
import ToggleMenuButton from "@/Components/Nav/NavMenu/ToggleMenuButton.vue";
import DepositWidget from "@/Components/Widgets/DepositWidget.vue";
import { initFlowbite, Modal } from "flowbite";
import { useBannerStore } from "@/Stores/BannerStore.js";
import { useToast } from "vue-toastification";
import { trans } from "laravel-vue-i18n";

const props = defineProps(["simple"]);

const authStore = useAuthStore();
const settingStore = useSettingStore();
const modalStore = useModalStore();
const bannerStore = useBannerStore();
const sidebarMenuStore = sidebarStore();

const toast = useToast();

const isAuthenticated = computed(() => authStore.isAuth);
const setting = computed(() => settingStore.setting);
const sidebarIsOpen = computed(() => sidebarMenuStore.sidebarStatus);
const banner = computed(() => bannerStore.depositBanner);

const modalDeposit = ref(null);
const paymentType = ref(null);
const modalWithdrawal = ref(null);

const emit = defineEmits([
	"toggle-menu",
	"login-toggle",
	"register-toggle",
	"profile-toggle",
	"logout-account",
]);

const toggleMenu = () => {
	emit("toggle-menu");
};

const loginToggle = () => {
	emit("login-toggle");
};

const registerToggle = () => {
	emit("register-toggle");
};

const profileToggle = () => {
	emit("profile-toggle");
};

const logoutAccount = () => {
	emit("logout-account");
};

const fetchDepositBannerIfNeeded = async () => {
	if (!bannerStore.depositBanner) {
		try {
			await bannerStore.fetchDepositBanner();
		} catch (error) {
			toast.error("Erro ao carregar o banner de depósito.");
			console.error("Erro ao buscar depositBanner:", error);
		}
	}
};

onMounted(async () => {
	initFlowbite();

	modalDeposit.value = new Modal(document.getElementById("modalElDeposit"), {
		placement: "center",
		backdrop: "dynamic",
		backdropClasses: "bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-40",
		closable: true,
		onHide: () => {
			paymentType.value = null;
			modalStore.closeDepositModal();
		},
	});

    modalWithdrawal.value = new Modal(document.getElementById("modalElWithdrawal"), {
        placement: "center",
        backdrop: "dynamic",
        backdropClasses: "bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-40",
        closable: true,
        onHide: () => {
        modalStore.closeWithdrawalModal();
        },
    });

	watch(
		() => modalStore.isDepositModalOpen,
		(newVal) => {
			if (newVal) {
				modalDeposit.value.show();
			} else {
				modalDeposit.value.hide();
			}
		},
	);

    watch(
    () => modalStore.isWithdrawalModalOpen,
        (newVal) => {
        if (newVal) {
            modalWithdrawal.value.show();
        } else {
            modalWithdrawal.value.hide();
        }
        },
    );

	await fetchDepositBannerIfNeeded();

	const sessionMessage = sessionStorage.getItem("sessionMessage");
	if (sessionMessage) {
		setTimeout(() => {
			toast.success(sessionMessage);
			sessionStorage.removeItem("sessionMessage");
		}, 2000);
	}
});
</script>

<template>
    <div class="nav-menu flex items-center gap-6">
        <NavigationLinks/>
        <div class="w-full">
            <div :class="{
                 'max-w-6xl px-3': sidebarIsOpen,
                 'md:pl-9 md:pr-[17rem]': !sidebarIsOpen
            }"
                 class="mx-auto flex items-center justify-between py-2">
                <div class="flex items-center justify-start">
                    <ToggleMenuButton @toggle-menu="toggleMenu"/>
                    <div class="flex items-center gap-3 hover:opacity-90">
                        <ApplicationLogo :setting="setting"/>
                        <MissionButton/>
                    </div>
                </div>
                <div class="hidden md:block"></div>
                <AuthButtons v-if="!isAuthenticated"
                             @login-toggle="loginToggle"
                             @register-toggle="registerToggle"/>

                <div class="flex items-center gap-2" v-else>
                    <MakeDeposit v-if="isAuthenticated"/>
                    <WalletBalance v-if="isAuthenticated"/>
                    <UserMenu @profile-toggle="profileToggle" @logout-account="logoutAccount"/>
                </div>
            </div>
        </div>
    </div>

    <div
        id="modalElDeposit"
        tabindex="-1"
        aria-hidden="true"
        class="fixed inset-0 z-[101] flex items-center justify-center hidden overflow-y-auto bg-black bg-opacity-20"
        @click="modalStore.closeDepositModal"
    >
        <div id="outside" class="w-full max-h-[100dvh] sm:max-h-full flex items-center justify-center z-[101]">
            <div
                class="w-full h-[100dvh] sm:max-w-lg bg-gray-900 rounded-lg shadow-xl md:h-full overflow-y-auto"
                @click.stop
            >
                <div class="relative">
                    <img
                        v-if="banner && banner.image"
                        :src="`/storage/${banner.image}`"
                        alt="Banner"
                        class="w-full h-32 sm:h-40 object-cover rounded-t-lg"
                    >
                    <button
                        @click="modalStore.closeDepositModal"
                        class="absolute text-white text-2xl"
                        style="top: calc(0.5rem + env(safe-area-inset-top)); right: calc(0.5rem + env(safe-area-inset-right));"
                    >
                        <i class="fa-solid fa-xmark"></i>
                    </button>
                </div>
                <div class="p-5 md:max-h-[calc(100dvh-4rem)] overflow-y-auto hide-scrollbar">
                    <div class="mb-3 text-center">
                        <h1 class="font-bold text-xl">{{ $t('Deposit') }}</h1>
                        <p><small>{{ $t('Choose your payment method') }}</small></p>
                    </div>
                    <DepositWidget/>
                </div>
            </div>
        </div>
    </div>
    <div
    id="modalElWithdrawal"
    tabindex="-1"
    aria-hidden="true"
    class="fixed inset-0 z-[101] flex items-center justify-center hidden overflow-y-auto bg-black bg-opacity-20"
    @click="modalStore.closeWithdrawalModal"
  >
    <div id="outside" class="w-full max-h-[100dvh] sm:max-h-full flex items-center justify-center z-[101]">
      <div
        class="w-full h-[100dvh] sm:max-w-lg bg-gray-900 rounded-lg shadow-xl md:h-full overflow-y-auto"
        @click.stop
      >
        <div class="relative">
          <button
            @click="modalStore.closeWithdrawalModal"
            class="absolute text-white text-2xl"
            style="top: calc(0.5rem + env(safe-area-inset-top)); right: calc(0.5rem + env(safe-area-inset-right));"
          >
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>
        <div class="p-5 md:max-h-[calc(100dvh-4rem)] overflow-y-auto hide-scrollbar">
          <div class="mb-3 text-center">
            <h1 class="font-bold text-xl">{{ $t('Withdrawal') }}</h1>
            <p><small>{{ $t('Enter your bank account details') }}</small></p>
          </div>
          <WithdrawalWidget />
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.hide-scrollbar {
    -ms-overflow-style: none !important; /* IE and Edge */
    scrollbar-width: none !important; /* Firefox */
}

.hide-scrollbar::-webkit-scrollbar {
    display: none !important;; /* Chrome, Safari, and Opera */
}
</style>
