<script setup>
import { ref, onMounted, onBeforeUnmount } from "vue";
import BaseProfileLayout from "@/Layouts/BaseProfileLayout.vue";
import { useMenuBarStore } from "@/Stores/MenuBarStore.js";

const isMobile = ref(false);
const menuBarStore = useMenuBarStore();

const checkIfMobile = () => {
	isMobile.value = window.innerWidth <= 768;
};

const showTooltip = ref(false);
const isLoading = ref(true);
const user = ref({
	email: "user@example.com",
	email_verified_at: true,
	two_fa_enabled: false,
	password_status: "Segura",
	social_accounts: [],
});

const fetchUserData = async () => {
	try {
	} catch (error) {
	} finally {
		isLoading.value = false;
	}
};

onMounted(() => {
	menuBarStore.setActiveMenu("profile");
	checkIfMobile();
	window.addEventListener("resize", checkIfMobile);
	fetchUserData();
});

onBeforeUnmount(() => {
	window.removeEventListener("resize", checkIfMobile);
});
</script>

<template>
    <BaseProfileLayout>
        <div>
            <h1
                class="mb-4 text-2xl font-semibold"
                style="color: var(--title-color)"
            >
                {{ $t("Account Security") }}
            </h1>
            <p class="mb-6 text-lg" style="color: var(--text-color)">
                {{ $t("Manage your account security settings below:") }}
            </p>
            <div v-if="isLoading">
                <p class="mb-6 text-lg" style="color: var(--text-color)">
                    {{ $t("Loading information...") }}
                </p>
            </div>
            <div v-else class="lg:max-w-[62rem]">
                <section class="mb-6">
                    <div class="flex items-center justify-between">
                        <div class="flex items-center">
                            <h2
                                class="text-lg font-semibold"
                                style="color: var(--title-color)"
                            >
                                {{ $t("Change Password") }}
                            </h2>
                            <span
                                :class="
                                    user.password_status === 'Segura'
                                        ? 'bg-green-500'
                                        : 'bg-red-500'
                                "
                                class="px-3 py-1 ml-4 text-xs font-semibold text-white rounded-full"
                            >
                                {{
                                    $t(
                                        user.password_status === "Secure"
                                            ? "Secure"
                                            : "Weak"
                                    )
                                }}
                            </span>
                        </div>
                        <button style="color: var(--text-color)">Editar</button>
                    </div>
                    <p class="mt-3" style="color: var(--text-color)">
                        <span>******</span>
                    </p>
                    <hr
                        class="my-4 border-t"
                        style="border-color: var(--sub-text-color)"
                    />
                </section>

                <section class="mb-6">
                    <div class="flex items-center">
                        <h2
                            class="text-lg font-semibold"
                            style="color: var(--title-color)"
                        >
                            {{ $t("Two-Factor Authentication (2FA)") }}
                        </h2>
                        <span
                            class="ml-4 cursor-pointer tooltip-container"
                            @mouseover="showTooltip = true"
                            @mouseleave="showTooltip = false"
                        >
                            <svg
                                height="1em"
                                viewBox="0 0 512 512"
                                width="1em"
                                xmlns="http://www.w3.org/2000/svg"
                                class="relative"
                            >
                                <path
                                    d="M256 192c17.67 0 32-14.33 32-32c0-17.67-14.33-32-32-32S224 142.3 224 160C224 177.7 238.3 192 256 192zM296 336h-16V248C280 234.8 269.3 224 256 224H224C210.8 224 200 234.8 200 248S210.8 272 224 272h8v64h-16C202.8 336 192 346.8 192 360S202.8 384 216 384h80c13.25 0 24-10.75 24-24S309.3 336 296 336z"
                                    fill="currentColor"
                                ></path>
                                <path
                                    d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 128c17.67 0 32 14.33 32 32c0 17.67-14.33 32-32 32S224 177.7 224 160C224 142.3 238.3 128 256 128zM296 384h-80C202.8 384 192 373.3 192 360s10.75-24 24-24h16v-64H224c-13.25 0-24-10.75-24-24S210.8 224 224 224h32c13.25 0 24 10.75 24 24v88h16c13.25 0 24 10.75 24 24S309.3 384 296 384z"
                                    fill="currentColor"
                                    opacity="0.4"
                                ></path>
                            </svg>
                            <div v-if="showTooltip" class="tooltip">
                                {{
                                    $t(
                                        "Two-factor authentication adds an extra layer of security. When enabled, it will protect your account with a second authentication factor in addition to your password."
                                    )
                                }}
                            </div>
                        </span>
                    </div>
                    <p class="mt-3" style="color: var(--text-color)">
                        {{ $t("Disabled") }}
                    </p>
                    <hr
                        class="my-4 border-t"
                        style="border-color: var(--sub-text-color)"
                    />
                </section>
                <section class="mb-6">
                    <div class="flex items-center justify-between">
                        <h2
                            class="text-lg font-semibold"
                            style="color: var(--title-color)"
                        >
                            {{ $t("Social Media Accounts") }}
                        </h2>
                        <button style="color: var(--text-color)">Editar</button>
                    </div>
                    <p class="mt-3" style="color: var(--text-color)">
                        {{
                            user.social_accounts.length
                                ? user.social_accounts.join(", ")
                                : $t("No accounts connected.")
                        }}
                    </p>
                    <hr
                        class="my-4 border-t"
                        style="border-color: var(--sub-text-color)"
                    />
                </section>
            </div>
        </div>
    </BaseProfileLayout>
</template>
