<script setup>
import { onMounted, ref } from "vue";
import { useLanguagesStore } from "@/Stores/LanguagesStore.js";
import { useAuthStore } from "@/Stores/Auth.js";
import HttpApi from "@/Services/HttpApi.js";

const languagesStore = useLanguagesStore();
const authStore = useAuthStore();

const languages = languagesStore.languages;
const selectedLanguage = ref("en");
const user = authStore.user;

const showLanguageDropdown = ref(false);

const toggleLanguageDropdown = () => {
	showLanguageDropdown.value = !showLanguageDropdown.value;
};

const selectLanguage = async (locale) => {
	try {
		await HttpApi.post("/set-locale", { locale });
		localStorage.setItem("selectedLanguage", locale);
		selectedLanguage.value = locale;
		location.reload();
	} catch (error) {
		console.error(
			"Erro ao alterar o idioma:",
			error.response?.data || error.message,
		);
	}
};

const getSelectedLanguageName = () => {
	const language = languages.find(
		(lang) => lang.code === selectedLanguage.value,
	);
	return language ? language.name : "";
};

const getSelectedLanguageFlag = () => {
	const language = languages.find(
		(lang) => lang.code === selectedLanguage.value,
	);
	return language ? language.flag : "";
};

onMounted(() => {
	const storedLanguage = localStorage.getItem("selectedLanguage");
	if (storedLanguage) {
		selectedLanguage.value = storedLanguage;
	} else if (user && user.language) {
		selectedLanguage.value = user.language;
	}
});
</script>

<template>
    <div class="flex justify-center">
        <div class="relative inline-block text-left">
            <button
                @click="toggleLanguageDropdown"
                type="button"
                class="language-selector-button inline-flex items-center justify-center w-full rounded-md px-4 py-2 text-sm font-medium focus:outline-none"
            >
                <img
                    :src="getSelectedLanguageFlag()"
                    :alt="getSelectedLanguageName()"
                    class="w-4 h-4 mr-2"
                />
                <span>{{ getSelectedLanguageName() }}</span>
                <svg
                    :class="[
                        'ml-2 h-4 w-4 transition-transform duration-200',
                        { 'rotate-180': showLanguageDropdown },
                    ]"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                >
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M19 9l-7 7-7-7"
                    />
                </svg>
            </button>
            <div
                v-if="showLanguageDropdown"
                class="language-dropdown origin-bottom-center absolute left-1/2 transform -translate-x-1/2 bottom-full mb-2 w-44 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
            >
                <div class="py-1">
                    <button
                        v-for="language in languages"
                        :key="language.code"
                        @click="selectLanguage(language.code)"
                        class="w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center"
                    >
                        <img
                            :src="language.flag"
                            :alt="language.name"
                            class="w-4 h-4 mr-2"
                        />
                        <span>{{ language.name }}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.language-dropdown {
    max-height: 220px;
    overflow-y: auto;
    z-index: 50;
}

.language-dropdown::-webkit-scrollbar {
    width: 8px;
}

.language-dropdown::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.language-dropdown::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
}

.language-dropdown::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.origin-bottom-center {
    transform-origin: bottom center;
}

.relative.inline-block.text-left {
    width: auto;
}

.inline-flex.items-center.justify-center {
    cursor: pointer;
}

.inline-flex:focus {
    outline: none;
}

.inline-flex svg {
    width: 1em;
    height: 1em;
    transition: transform 0.2s;
}

.rotate-180 {
    transform: rotate(180deg);
}

.language-selector-button {
    background-color: #fdffff0d;
    border: 1px solid rgba(253, 255, 255, 0.1);
    --tw-text-opacity: 1;
    color: rgb(253 255 255 / var(--tw-text-opacity));
}

.language-selector-button:hover {
    background-color: rgba(253, 255, 255, 0.2);
    border-color: rgba(253, 255, 255, 0.2);
}
</style>
