import { defineStore } from "pinia";
import { ref } from "vue";

export const useFooterStore = defineStore("footer", {
	state() {
		return {
			footerText: ref(
				`Is operated by Rocket Gaming Corporation, with registered agent address at Legalinc Corporate Services Inc., 131 Continental Dr Suite 305 Newark, DE, 19713 US and is licensed and authorized by the Government of Anjouan and operates under the Master License of Gaming Services Provider. Payment agent company is Stripe, Inc. Only accepts customers over 18 years of age.`,
			),

			columns: ref([
				{
					id: 1,
					icon: "fas fa-info-circle",
					title: "about us",
					links: [
						{ id: 1, label: "legal docs", url: "#" },
						{ id: 2, label: "faq", url: "#" },
						{ id: 3, label: "about us", url: "#" },
						{ id: 4, label: "careers", url: "#" },
					],
				},
				{
					id: 2,
					icon: "fas fa-wallet",
					title: "transactions",
					links: [
						{ id: 1, label: "payment methods", url: "#" },
						{ id: 2, label: "payment limits", url: "#" },
						{ id: 3, label: "deposit", url: "#" },
						{ id: 4, label: "withdrawal", url: "#" },
					],
				},
				{
					id: 3,
					icon: "fas fa-lock",
					title: "security",
					links: [
						{ id: 1, label: "bonus policy", url: "#" },
						{ id: 2, label: "privacy policy", url: "#" },
						{ id: 3, label: "affiliation terms", url: "#" },
						{ id: 4, label: "terms of use", url: "/terms/service" },
					],
				},
				{
					id: 4,
					icon: "fas fa-question-circle",
					title: "support",
					links: [
						{ id: 1, label: "contact form", url: "#" },
						{ id: 2, label: "responsible gaming", url: "#" },
						{ id: 3, label: "game provider", url: "#" },
						{ id: 4, label: "platform rules", url: "#" },
					],
				},
				{
					id: 5,
					icon: "fas fa-credit-card",
					title: "payments",
					links: [
						{
							id: 1,
							label: "apple pay",
							url: "#",
							iconSvg: "/icons/apay.svg",
							className: "icon-apay",
						},
						{
							id: 2,
							label: "elo",
							url: "#",
							iconSvg: "/icons/elo.svg",
							className: "icon-elo",
						},
						{
							id: 3,
							label: "mastercard",
							url: "#",
							iconSvg: "/icons/mc.svg",
							className: "icon-mastercard",
						},
						{
							id: 4,
							label: "visa",
							url: "#",
							iconSvg: "/icons/visa.svg",
							className: "icon-visa",
						},
					],
				},
			]),

			logoTM: ref({
				id: 1,
				src: "/icons/world.svg",
				alt: "Icone Global",
				width: "50px",
			}),
		};
	},
});
