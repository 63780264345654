import { defineStore } from "pinia";
import HttpApi from "@/Services/HttpApi.js";

export const useLanguagesStore = defineStore("LanguagesStore", {
	state() {
		return {
			languages: [
				{ code: "es", name: "Español", flag: "/icons/es.svg" },
				{ code: "en", name: "English", flag: "/icons/us.svg" },
				{ code: "pt_BR", name: "Português", flag: "/icons/br.svg" },
			],
			selected: "es",
		};
	},

	actions: {
		async fetch() {
			try {
			} catch (error) {
				console.error("Erro ao buscar os idiomas:", error);
			}
		},
	},
});
